export class Message {

    constructor (
        public lastmodifiedts: string,
        public totalAttachments: string,
        public CustomerRequest_id: string,
        public MessageDescription: string,
        public createdby_name: string,
        public IsRead: string,
        public createdts: string,
        public RepliedBy: string,
        public softdeleteflag: string,
        public ReplySequence: string,
        public createdby: string,
        public Message_id: string,
        public id: string,
        public synctimestamp: string,
    ) {}
}