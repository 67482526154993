import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { delay, of } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { Router } from '@angular/router';

import * as AuthActions from '../actions/auth.actions';
import { selectError } from '../selectors/auth.selectors';
import { NavigationService } from '../../../../shared/services/navigation.service';
import { LoginUseCase } from '../../application/use-cases/login.usecase';
import { RegisterUseCase } from '../../application/use-cases/register.use-case';
import { AuthTokenService } from '../../application/services/auth-token.service';
import { GetLegalEntityUseCase } from '../../application/use-cases/get-legal-entity.use-case';
import { ActivateAccountUseCase } from '../../application/use-cases/activate-account.use-case';
import { CreatePasswordUseCase } from '../../application/use-cases/create-password.use-case';
import { SecureHashTokenService } from '../../../../shared/services/secure-hash-token.service';
import { AuthError } from '../../domain/entities/error.entity';
import { PasswordForgottenUseCase } from '../../application/use-cases/password-forgotten.use-case';
import { ResendOTPUseCase } from '../../application/use-cases/resend-otp.use-case';
import { ResetPasswordUseCase } from '../../application/use-cases/reset-password.use-case';
import { VerifyOTPUseCase } from '../../application/use-cases/verify-otp.use-case';
import { LogoutUseCase } from '../../application/use-cases/logout.use-case';
import { GetUserContractsUseCase } from '../../application/use-cases/get-user-contracts.use-case';
import { TermsAndConditionsUseCase } from '../../application/use-cases/terms-and-conditions.use-case';
import { ResendActivateCodeUseCase } from '../../application/use-cases/resend-activate-code.use-case';

@Injectable()
export class AuthEffects {
	login$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.login),
			mergeMap((action) =>
				this.loginUseCase.execute(action.credentials).pipe(
					map((authSession) =>
						AuthActions.loginSuccess({
							token: authSession.token,
							refreshToken: authSession.refreshToken,
							user: authSession.user,
						})
					),
					catchError((error) =>
						of(AuthActions.loginFailure({ error: error.code }))
					)
				)
			)
		)
	);

	loginSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.loginSuccess),
			tap((action) => {
				this.tokenService.setToken(action.token, action.refreshToken);
				this.tokenService.setLegalEntity(action.user.legalEntityId);
			}),
			mergeMap(() => [AuthActions.getUserContracts()])
		)
	);

	register$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.register),
			mergeMap((action) =>
				this.registerUseCase.execute(action.user).pipe(
					map((registerData) =>
						AuthActions.registerSuccess({ registerData })
					),
					catchError((error: AuthError) =>
						of(AuthActions.registerFailure({ error: error.code }))
					)
				)
			)
		)
	);

	registerSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(AuthActions.registerSuccess),
				tap((data) => {
					const encodedUsername = this.secureHashTokenService.signToken(
						data.registerData.userName
					);
					const encodedLegalEntityId =
						this.secureHashTokenService.signToken(
							data.registerData.legalEntityId
						);
					this.navigationService.navigateToActivateAccount(
						encodedUsername,
						encodedLegalEntityId
					);
				})
			),
		{ dispatch: false }
	);

	activateAccount$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.activateAccount),
			mergeMap((action) =>
				this.activateAccountUseCase
					.execute({
						username: action.userName,
						activationCode: action.activationCode,
					})
					.pipe(
						map((serviceKey) =>
							AuthActions.activateAccountSuccess({ serviceKey })
						),
						catchError((error) =>
							of(
								AuthActions.activateAccountFailure({
									error: error.code,
								})
							)
						)
					)
			)
		)
	);

	activateAccountSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(AuthActions.activateAccountSuccess),
				tap((data) => {
					const encodedServiceKey = this.secureHashTokenService.signToken(
						data.serviceKey
					);
					this.navigationService.navigateToCreatePassword(
						encodedServiceKey
					);
				})
			),
		{ dispatch: false }
	);

	createPassword$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.createPassword),
			mergeMap((action) =>
				this.createPasswordUseCase
					.execute({
						serviceKey: action.serviceKey,
						password: action.password,
					})
					.pipe(
						map(() => AuthActions.createPasswordSuccess()),
						catchError((error) =>
							of(
								AuthActions.createPasswordFailure({ error: error.code })
							)
						)
					)
			)
		)
	);

	createPasswordSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(AuthActions.createPasswordSuccess),
				tap(() => this.navigationService.navigateToTermsConditions())
			),
		{ dispatch: false }
	);

	logout$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.logout),
			mergeMap(() =>
				this.logoutUseCase.execute().pipe(
					map(() => AuthActions.logoutSuccess()),
					catchError((error) => of(AuthActions.logoutFailure({ error })))
				)
			)
		)
	);

	logoutSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(AuthActions.logoutSuccess),
				tap(() => {
					this.tokenService.removeTokens();
					this.navigationService.navigateToLogin();
				})
			),
		{ dispatch: false }
	);

	getUserContracts$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.getUserContracts),
			mergeMap(() =>
				this.getUserContractsUseCase.execute().pipe(
					map((contracts) =>
						AuthActions.getUserContractsSuccess({ contracts })
					),
					catchError((error) =>
						of(AuthActions.getUserContractsFailure({ error }))
					)
				)
			)
		)
	);

	getLegalEntity$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.getLegalEntity),
			mergeMap(() =>
				this.getLegalEntityUseCase.execute().pipe(
					map((legalEntity) =>
						AuthActions.getLegalEntitySuccess({ legalEntity })
					),
					catchError((error) =>
						of(AuthActions.getLegalEntityFailure({ error }))
					)
				)
			)
		)
	);

	passwordForgotten$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.passwordForgotten),
			mergeMap((action) =>
				this.passwordForgottenUseCase.execute(action.credentials).pipe(
					mergeMap((passwordForgottenResponse) => {
						return this.resendOTPUseCase
							.execute({
								...action.credentials,
								phone: action.credentials.mobilePhoneNumber,
								email: action.credentials.email,
								userName:
									passwordForgottenResponse.user_attributes[0]
										.UserName,
								serviceKey: passwordForgottenResponse.serviceKey,
							})
							.pipe(
								map((resendOTPResponse) =>
									AuthActions.passwordForgottenSuccess({
										...passwordForgottenResponse, // Les données de la première requête
										email: action.credentials.email,
										phone: action.credentials.mobilePhoneNumber,
										securityKey:
											resendOTPResponse.MFAAttributes.securityKey,
										sacCodeLength:
											resendOTPResponse.MFAAttributes.sacCodeLength,
									})
								)
							);
					}),
					catchError((error) =>
						of(
							AuthActions.passwordForgottenFailure({ error: error.code })
						)
					)
				)
			)
		)
	);

	clearErrorAfterDelay$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				AuthActions.loginFailure,
				AuthActions.registerFailure,
				AuthActions.activateAccountFailure,
				AuthActions.createPasswordFailure,
				AuthActions.getUserContractsFailure,
				AuthActions.passwordForgottenFailure,
				AuthActions.resendOTPFailure,
				AuthActions.resetPasswordFailure,
				AuthActions.verifyOTPFailure,
				AuthActions.termsAndConditionsFailure,
				AuthActions.resendActivateCode
			),
			delay(5000),
			withLatestFrom(this.store.select(selectError)),
			mergeMap(([_, error]) => {
				if (error) {
					return of(AuthActions.clearAuthError());
				}
				return of({ type: 'NO_ACTION' });
			})
		)
	);

	passwordForgottenSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(AuthActions.passwordForgottenSuccess),
				tap((action) => {
					const encodedServiceKey = this.secureHashTokenService.signToken(
						action?.serviceKey
					);
					const encodedSecurityKey = this.secureHashTokenService.signToken(
						action?.securityKey
					);
					let userName = '';
					if (action?.user_attributes.length > 0) {
						userName = action.user_attributes[0].UserName;
					}
					// Redirection avec certaines informations dans l'URL
					this.router.navigate(['auth/verify-otp'], {
						queryParams: {
							sk: encodedServiceKey,
							secure: encodedSecurityKey,
							sacCodeLength: action.sacCodeLength,
							email: action.email,
							phone: action.phone,
							userName,
						},
					});
				})
			),
		{ dispatch: false }
	);

	resendOTP$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.resendOTP),
			mergeMap((action) =>
				this.resendOTPUseCase.execute(action.credentials).pipe(
					map((response) => {
						const encodedServiceKey =
							this.secureHashTokenService.signToken(
								response.MFAAttributes.serviceKey
							);
						const encodedSecurityKey =
							this.secureHashTokenService.signToken(
								response.MFAAttributes.securityKey
							);
						this.router.navigate([], {
							queryParams: {
								sk: encodedServiceKey,
								secure: encodedSecurityKey,
							},
							queryParamsHandling: 'merge',
						});
						return AuthActions.resendOTPSuccess(response);
					}),
					catchError((error) =>
						of(AuthActions.resendOTPFailure({ error: error.code }))
					)
				)
			)
		)
	);

	resetPassword$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.resetPassword),
			mergeMap((action) =>
				this.resetPasswordUseCase.execute(action.credentials).pipe(
					map((response) => AuthActions.resetPasswordSuccess(response)),
					catchError((error) =>
						of(AuthActions.resetPasswordFailure({ error: error.code }))
					)
				)
			)
		)
	);

	resetPasswordSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(AuthActions.resetPasswordSuccess),
				tap((action) => {
					this.router.navigate(['auth/login']);
				})
			),
		{ dispatch: false }
	);

	verifyOTP$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.verifyOTP),
			mergeMap((action) => {
				return this.verifyOTPUseCase.execute(action.credentials).pipe(
					map((response) =>
						AuthActions.verifyOTPSuccess({
							...response,
							userName: action.credentials.userName,
							serviceKey: action.credentials.serviceKey,
						})
					),
					catchError((error) =>
						of(AuthActions.verifyOTPFailure({ error: error.code }))
					)
				);
			})
		)
	);

	verifyOTPSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(AuthActions.verifyOTPSuccess),
				tap((action) => {
					const encodedServiceKey = this.secureHashTokenService.signToken(
						action.serviceKey
					);
					// Redirection avec certaines informations dans l'URL
					this.navigationService.navigateToCreatePasswordForForgottenPassword(
						encodedServiceKey,
						action.userName
					);
				})
			),
		{ dispatch: false }
	);

	termsAndConditions$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.termsAndConditions),
			mergeMap(() => {
				return this.termsAndConditionsUseCase.execute().pipe(
					map((response) =>
						AuthActions.termsAndConditionsSuccess({
							termsAndConditionsContent:
								response.termsAndConditionsContent,
							contentTypeId: response.contentTypeId,
							versionId: response.versionId,
						})
					),
					catchError((error) =>
						of(
							AuthActions.termsAndConditionsFailure({
								error: error.code,
							})
						)
					)
				);
			})
		)
	);

	resendActivateCode$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.resendActivateCode),
			mergeMap((action) =>
				this.resendActivateCodeUseCase.execute(action.credentials).pipe(
					map((response) => {
						return AuthActions.resendActivateCodeSuccess(response);
					}),
					catchError((error) =>
						of(
							AuthActions.resendActivateCodeFailure({
								error: error.code,
							})
						)
					)
				)
			)
		)
	);

	constructor(
		private actions$: Actions,
		private loginUseCase: LoginUseCase,
		private registerUseCase: RegisterUseCase,
		private activateAccountUseCase: ActivateAccountUseCase,
		private createPasswordUseCase: CreatePasswordUseCase,
		private getLegalEntityUseCase: GetLegalEntityUseCase,
		private tokenService: AuthTokenService,
		private secureHashTokenService: SecureHashTokenService,
		private router: Router,
		private passwordForgottenUseCase: PasswordForgottenUseCase,
		private resendOTPUseCase: ResendOTPUseCase,
		private resetPasswordUseCase: ResetPasswordUseCase,
		private verifyOTPUseCase: VerifyOTPUseCase,
		private store: Store,
		private navigationService: NavigationService,
		private logoutUseCase: LogoutUseCase,
		private getUserContractsUseCase: GetUserContractsUseCase,
		private termsAndConditionsUseCase: TermsAndConditionsUseCase,
		private resendActivateCodeUseCase: ResendActivateCodeUseCase
	) {}
}
