import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IBillRepository } from "../../domain/repositories/bill.repository";
import { PartnerService } from "../../domain/entities/partner-service.entity";

@Injectable({
    providedIn: 'root'
})
export class GetServicesByPartnerIdUseCase {
    constructor(@Inject('IBillRepository') private billRepository: IBillRepository) {}

    execute(partnerId: number): Observable<PartnerService[]> {
        return this.billRepository.getServicesByPartnerId(partnerId);
    }
}
