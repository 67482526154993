<div class="min-h-screen flex flex-col">
	<!-- Header -->
	<app-header (menuClick)="toggleSidebar()"></app-header>

	<div class="flex flex-1">
		<!-- Sidebar -->
		<app-sidebar
			[visible]="sidebarVisible"
			(clickOutside)="closeSidebar()"
		></app-sidebar>

		<!-- Main Content -->
		<main class="flex-1">
			<ng-container *ngIf="showUserAccountSection">
				<!--<app-user-account-section class="w-full"></app-user-account-section> --->
			</ng-container>
			<div class="bg-[#F2F2F2]">
				<router-outlet></router-outlet>
			</div>
		</main>
	</div>

	<!-- Footer -->
	<app-footer></app-footer>
</div>
