import {Inject, Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { IAuthRepository } from '../../domain/repositories/auth.repository';
import {CreatePasswordDTO} from "../dtos/authentication.dto";

@Injectable({
    providedIn: 'root'
})
export class CreatePasswordUseCase {
    constructor(@Inject('IAuthRepository') private authRepository: IAuthRepository) {}

    execute(data: CreatePasswordDTO): Observable<boolean> {
        return this.authRepository.createPassword(data);
    }
}
