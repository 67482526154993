export const MENU_DATA = [
	// Transfer Section
	{
		url: '/transfer/plan-transfer',
		menuItems: [
			{
				title: 'MENU_ITEMS.TRANSFER_HISTORY',
				link: '/transfer/plan-transfer/history',
			},
			{ title: 'MENU_ITEMS.VALIDATE_A_TRANSFER', link: '/' },
		],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{
				title: 'BREADCRUMB_ITEMS.TRANSFERS_MANAGEMENT',
				link: '/beneficiary',
			},
		],
	},
	{
		url: '/beneficiary',
		menuItems: [
			{
				title: 'MENU_ITEMS.CREATE_A_BENEFICIARY',
				link: '/beneficiary/new',
			},
			{
				title: 'MENU_ITEMS.VALIDATE_A_TRANSFER',
				link: '/beneficiary/validate',
			},
		],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'BREADCRUMB_ITEMS.TRANSFERS', link: '/transfer' },
			{
				title: 'BREADCRUMB_ITEMS.BENEFICIARY_MANAGEMENT',
				link: '/beneficiary',
			},
		],
	},
	{
		url: '/beneficiary/new',
		menuItems: [
			{
				title: 'MENU_ITEMS.LIST_OF_BENEFICIARIES',
				link: '/beneficiary',
			},
			{
				title: 'MENU_ITEMS.VALIDATE_A_BENEFICIARY',
				link: '/beneficiary/validate',
			},
		],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'BREADCRUMB_ITEMS.TRANSFERS', link: '/transfer' },
			{
				title: 'BREADCRUMB_ITEMS.BENEFICIARY_MANAGEMENT',
				link: '/beneficiary',
			},
			{
				title: 'BREADCRUMB_ITEMS.CREATE_A_BENEFICIARY',
				link: '/beneficiary/new',
			},
		],
	},
	{
		url: '/beneficiary/validation',
		menuItems: [
			{ title: 'MENU_ITEMS.CREATE_A_BENEFICIARY', link: '/beneficiary/new' },
			{ title: 'MENU_ITEMS.LIST_OF_BENEFICIARIES', link: '/beneficiary' },
		],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'BREADCRUMB_ITEMS.TRANSFERS', link: '/transfer' },
			{
				title: 'BREADCRUMB_ITEMS.BENEFICIARY_MANAGEMENT',
				link: '/beneficiary',
			},
			{
				title: 'BREADCRUMB_ITEMS.VALIDATE_A_BENEFICIARY',
				link: '/beneficiary/validate',
			},
		],
	},
	// Account Section
	{
		url: '/account',
		menuItems: [
			{
				title: 'MENU_ITEMS.MAKE_A_TRANSFER',
				link: '/transfer',
			},
			{
				title: 'MENU_ITEMS.PLANNED_TRANSFER',
				link: '/transfer/planned',
			},
		],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'BREADCRUMB_ITEMS.ACCOUNT', link: '/account' },
		],
	},
	{
		url: '/account/statement',
		menuItems: [
			{ title: 'RIB', link: '/account-statement/history' },
			{ title: 'MENU_ITEMS.MY_ACCOUNTS', link: '/' },
		],
	},
	// Transfer Section
	{
		url: '/transfer',
		menuItems: [
			{ title: 'MENU_ITEMS.TRANSFER_HISTORY', link: '/transfer/history' },
			{ title: 'MENU_ITEMS.LIST_OF_BENEFICIARIES', link: '/beneficiary' },
		],
	},
	{
		url: '/transfer/history',
		menuItems: [
			{ title: 'MENU_ITEMS.MAKE_A_TRANSFER', link: '/transfer' },
			{ title: 'MENU_ITEMS.CREATE_A_BENEFICIARY', link: '/beneficiary/new' },
		],
	},
	{
		url: '/transfer/planned',
		menuItems: [
			{ title: 'RIB', link: '/account-statement/history' },
			{ title: 'MENU_ITEMS.SCHEDULED_TRANSFERS', link: '/' },
		],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{
				title: 'BREADCRUMB_ITEMS.SCHEDULED_TRANSFERS',
				link: '/beneficiary',
			},
		],
	},
	{
		url: '/transfer/mass',
		menuItems: [{ title: 'MENU_ITEMS.HISTORY', link: '/transfer/history' }],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'BREADCRUMB_ITEMS.TRANSFERS', link: '/transfer' },
			{ title: 'BREADCRUMB_ITEMS.MASS_TRANSFERS', link: '/transfer/mass' },
		],
	},
	{
		url: '/transfer/mass/file-example',
		menuItems: [
			{
				title: 'MENU_ITEMS.MASS_TRANSFER_MANAGEMENT',
				link: '/transfer/mass',
			},
			{ title: 'MENU_ITEMS.HISTORY', link: '/transfer/history' },
		],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'BREADCRUMB_ITEMS.TRANSFERS', link: '/transfer' },
			{ title: 'BREADCRUMB_ITEMS.MASS_TRANSFERS', link: '/transfer/mass' },
			{
				title: 'BREADCRUMB_ITEMS.EXAMPLE_FILE',
				link: '/transfer/mass/file-example',
			},
		],
	},
	{
		url: '/transfer/new/internal',
		menuItems: [
			{
				title: 'MENU_ITEMS.EXTERNAL_TRANSFERS',
				link: '/transfer/new/external',
			},
			{ title: 'MENU_ITEMS.TRANSFER_HISTORY', link: '/transfer/history' },
			{
				title: 'MENU_ITEMS.APPROVAL_OF_PENDING_TRANSFERS',
				link: '/transfer/request/in-pending',
			},
		],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'BREADCRUMB_ITEMS.TRANSFERS', link: '/transfer' },
			{ title: 'BREADCRUMB_ITEMS.NEW_TRANSFER', link: '' },
			{ title: 'BREADCRUMB_ITEMS.INTERNAL_TRANSFERS', link: '/' },
		],
	},
	{
		url: '/transfer/new/external',
		menuItems: [
			{
				title: 'MENU_ITEMS.INTERNAL_TRANSFERS',
				link: '/transfer/new/internal',
			},
			{
				title: 'MENU_ITEMS.INTERNAL_TRANSFERS',
				link: '/transfer/history',
			},
			{
				title: 'MENU_ITEMS.APPROVAL_OF_PENDING_TRANSFERS',
				link: '/transfer/request/in-pending',
			},
		],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'BREADCRUMB_ITEMS.TRANSFERS', link: '/transfer' },
			{ title: 'BREADCRUMB_ITEMS.NEW_TRANSFER', link: '' },
			{ title: 'BREADCRUMB_ITEMS.EXTERNAL_TRANSFERS', link: '/' },
		],
	},
	{
		url: '/transfer/request/history',
		menuItems: [
			{ title: 'MENU_ITEMS.MAKE_A_TRANSFER', link: '/transfer' },
			{
				title: 'MENU_ITEMS.INTERNAL_TRANSFER',
				link: '/transfer/new/internal',
			},
			{
				title: 'MENU_ITEMS.EXTERNAL_TRANSFER',
				link: '/transfer/new/external',
			},
		],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'BREADCRUMB_ITEMS.TRANSFERS', link: '/transfer' },
			{
				title: 'BREADCRUMB_ITEMS.TRANSFER_REQUEST',
				link: '#',
			},
			{
				title: 'BREADCRUMB_ITEMS.TRANSFER_HISTORY',
				link: '/transfer/request/history',
			},
		],
	},
	{
		url: '/transfer/request/in-pending',
		menuItems: [
			{
				title: 'MENU_ITEMS.INTERNAL_TRANSFER',
				link: '/transfer/new/internal',
			},
			{
				title: 'MENU_ITEMS.EXTERNAL_TRANSFER',
				link: '/transfer/new/external',
			},
		],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'BREADCRUMB_ITEMS.TRANSFERS', link: '/transfer' },
			{
				title: 'BREADCRUMB_ITEMS.TRANSFER_REQUEST',
				link: '#',
			},
			{
				title: 'BREADCRUMB_ITEMS.APPROVAL_OF_PENDING_TRANSFERS',
				link: '/transfer/request/history',
			},
		],
	},
	{
		url: '/transfer/bill-payment',
		menuItems: [
			{ title: 'RIB', link: '/account-statement/history' },
			{ title: 'MENU_ITEMS.BILL_PAYMENT', link: '/' },
		],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'BREADCRUMB_ITEMS.BILL_PAYMENT', link: '/beneficiary' },
		],
	},
	{
		url: '/transfer/bill-payment/cnps',
		menuItems: [
			{ title: 'RIB', link: '/account-statement/history' },
			{ title: 'MENU_ITEMS.CNPS_INVOICE_PAYMENT', link: '/' },
		],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{
				title: 'BREADCRUMB_ITEMS.CNPS_INVOICE_PAYMENT',
				link: '/beneficiary',
			},
		],
	},
	// Bank and Wallet Section
	{
		url: '/bank-to-wallet',
		menuItems: [
			{ title: 'MENU_ITEMS.CONTACT_US', link: '/faq/about' },
			{ title: 'BANK TO WALLET', link: '/faq/services' },
		],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'BANK TO WALLET', link: '/faq' },
		],
	},
	{
		url: '/wallet-to-bank',
		menuItems: [
			{ title: 'MENU_ITEMS.CONTACT_US', link: '/faq/about' },
			{ title: 'WALLET TO BANK', link: '/faq/services' },
		],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'WALLET TO BANK', link: '/faq' },
		],
	},
	// FAQ Section
	{
		url: '/faq',
		menuItems: [
			{ title: 'MENU_ITEMS.CONTACT_US', link: '/contact-us' },
			{ title: 'MENU_ITEMS.FIND_US', link: '/find-us' },
		],
	},
];
