import {Observable} from "rxjs";
import {Transfer} from "../entities/transfer.entity";

export abstract class ITransferRepository {
    abstract transferToAnotherAccount(): Observable<Transfer>;
    abstract transferToExternalBank(): Observable<Transfer>;
    abstract transferToInternational(): Observable<Transfer>;
    abstract transferToPersonalAccount(): Observable<Transfer>;
    abstract validateTransferByOTP(code: string): Observable<boolean>;
    abstract getTransferHistory(): Observable<Transfer[]>;
    abstract recurringTransfer(): Observable<Transfer>;
}