import { DomainError } from '../../../../shared/errors/DomainError';

export class InfoError extends DomainError {
	static readonly UNKNOWN_ERROR = new InfoError(
		'INFO.ERRORS.UNKNOWN_ERROR',
		'Une erreur inconnue est survenue',
		500
	);
	static readonly CONTACT_US_ERROR = new InfoError(
		'CONTACT_US.ERRORS.UNKNOWN_ERROR',
		'Une erreur inconnue est survenue',
		500
	);

	constructor(
		code: string,
		message: string,
		status?: number,
		originalError?: any
	) {
		super(code, message, status, originalError);
	}

	static fromError(error: {
		code: string;
		message: string;
		httpStatusCode?: number;
	}): InfoError {
		switch (error.code) {
			case 'CONTACT_US_ERROR':
				return InfoError.CONTACT_US_ERROR;
			default:
				return new InfoError(
					'INFO.ERRORS.EXTERNAL_ERROR',
					error.message,
					error.httpStatusCode || 500,
					error
				);
		}
	}
}
