export class Beneficiary {
    constructor(
        public id: string,
        public accountNumber: string,
        public bankName: string,
        public nickName: string,
        public isSameBankAccount: string,
        public isInternationalAccount: string,
        public feature: string,
        public IBAN: string,
        public beneficiaryName: string,
        public addressLine1: string,
        public addressLine2: string,
        public city: string,
        public zipcode: string,
        public country: string,
        public email: string,
        public phoneNumber: string,
        public state: string,
        public swiftCode: string,
        public sameBank: string,
        public otherBank: string,
        public isVerified: string,
        public singleCustomer: string,
        public cif: any[],
        public payeeStatus: string,
        public payeeId: string
    ) {}
}
