import { LoginCredentialsDTO as CoreLoginCredentialsDTO } from '../../../core-banking/application/dtos/auth/user-login.dto';
import { LoginResponseDTO as CoreLoginResponseDTO } from '../../../core-banking/application/dtos/auth/user-login.dto';
import {
	UserRegistrationRequestDTO as CoreRegisterUserDTO,
	UserRegistrationResponseDTO,
} from '../../../core-banking/application/dtos/auth/user-registration.dto';
import {
	ActivateAccountDTO,
	AuthSessionDTO,
	CreatePasswordDTO,
	LoginCredentialsDTO,
	RegisterUserDTO,
	ResendOTPDTO,
	PasswordForgottenDTO,
	ResetPasswordDTO,
	VerifyOTPDTO,
} from '../dtos/authentication.dto';
import { AuthSession } from '../../domain/entities/auth-session.entity';
import { CreatePasswordRequestDTO as CoreCreatePasswordRequestDTO } from '../../../core-banking/application/dtos/auth/create-password.dto';
import { User } from '../../domain/entities/user.entity';
import { ActivateAccountRequestDTO as CoreActivateAccountRequestDTO } from '../../../core-banking/application/dtos/auth/user-active-account.dto';
import { LegalEntity } from '../../domain/entities/legal-entity.entity';
import {
	RequestResendOTPDTO,
	PasswordForgottenRequestDTO,
	ResetPasswordRequestDTO,
	RequestVerifyOTPDTO,
} from '../../../core-banking/application/dtos/auth/reset-password.dto';

export class AuthMapper {
	static toAuthSessionDTO(coreResponse: CoreLoginResponseDTO): AuthSessionDTO {
		return {
			token: coreResponse.claims_token!.value,
			refreshToken: coreResponse.refresh_token,
			expiry: new Date(coreResponse.claims_token!.exp * 1000),
			user: {
				userId: coreResponse.profile!.userid,
				firstName: coreResponse.profile!.user_attributes!.FirstName!,
				lastName: coreResponse.profile!.user_attributes!.LastName!,
				customerId: coreResponse.profile!.user_attributes!.customer_id!,
				legalEntityId:
					coreResponse.profile!.user_attributes!.legalEntityId!,
			},
		};
	}

	static toAuthSessionDomain(coreResponse: CoreLoginResponseDTO): AuthSession {
		return new AuthSession(
			coreResponse.claims_token!.value,
			coreResponse.refresh_token,
			{
				id: coreResponse.profile!.userid,
				firstName: coreResponse.profile!.user_attributes!.FirstName!,
				lastName: coreResponse.profile!.user_attributes!.LastName!,
				legalEntityId:
					coreResponse.profile!.user_attributes!.legalEntityId!,
				customerId: coreResponse.profile!.user_attributes!.customer_id!,
			}
		);
	}

	static toLoginCredentialsDTO(
		request: LoginCredentialsDTO
	): CoreLoginCredentialsDTO {
		return {
			userid: request.userid,
			Password: request.password,
		};
	}

	static toRegisterUserDTO(request: RegisterUserDTO): CoreRegisterUserDTO {
		return {
			lastName: request.lastName,
			customerId: request.customerId,
			dateOfBirth: request.dateOfBirth,
			legalEntityId: request.legalEntityId,
		};
	}

	static toUserDomain(coreResponse: UserRegistrationResponseDTO): User {
		return new User(
			'',
			coreResponse.firstName,
			coreResponse.lastName,
			'',
			coreResponse.email,
			coreResponse.userName,
			coreResponse.phoneNumber,
			coreResponse.activationCode
		);
	}

	static toActivateAccountDTO(
		request: ActivateAccountDTO
	): CoreActivateAccountRequestDTO {
		return {
			UserName: request.username,
			activationCode: request.activationCode,
		};
	}

	static toCreatePasswordDTO(
		request: CreatePasswordDTO
	): CoreCreatePasswordRequestDTO {
		return {
			serviceKey: request.serviceKey,
			Password: request.password,
		};
	}

	static toLegalEntityDomain(coreResponse: any): LegalEntity {
		return new LegalEntity(
			coreResponse.id,
			coreResponse.companyName,
			coreResponse.typeId,
			coreResponse.language,
			coreResponse.shortName,
			coreResponse.parentId,
			coreResponse.baseCurrency,
			coreResponse.countryCode,
			coreResponse.effectiveDate,
			coreResponse.closeDate
		);
	}
	static toPasswordForgottenDTO(
		request: PasswordForgottenDTO
	): PasswordForgottenRequestDTO {
		return {
			MobilePhoneNumber: request.mobilePhoneNumber,
			Email: request.email,
			DateOfBirth: request.dateOfBirth,
			LegalEntityId: request.legalEntityId,
		};
	}

	static toResendOTPDTO(request: ResendOTPDTO): RequestResendOTPDTO {
		return {
			Phone: request.phone,
			Email: request.email,
			UserName: request.userName,
			MFAAttributes: {
				serviceKey: request.serviceKey,
			},
		};
	}

	static toResetPassword(request: ResetPasswordDTO): ResetPasswordRequestDTO {
		return {
			UserName: request.userName,
			serviceKey: request.serviceKey,
			Password: request.password,
		};
	}
	static toVerifyOTP(request: VerifyOTPDTO): RequestVerifyOTPDTO {
		return {
			MFAAttributes: {
				serviceKey: request.serviceKey,
				OTP: {
					otp: request.otp,
					securityKey: request.securityKey,
				},
			},
		};
	}
}
