import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as InfoActions from '../actions/info.actions';
import { GetContactUSUseCase } from '../../application/use-cases/get-contact-us.use-case';
import { GetAgenceUseCase } from '../../application/use-cases/get-agences.use-case';

@Injectable()
export class InfoEffects {
	loadContactUs$ = createEffect(() =>
		this.actions$.pipe(
			ofType(InfoActions.loadContactUs),
			switchMap(() =>
				this.getContactUSUseCase.execute().pipe(
					map((contactUs) => {
						console.log('contactUs: ', contactUs);
						return InfoActions.loadContactUsSuccess({
							contactUs,
						});
					}),
					catchError((error) => {
						console.log('error: ', error);
						return of(
							InfoActions.loadContactUsFailure({
								error,
							})
						);
					})
				)
			)
		)
	);

	loadAgence$ = createEffect(() =>
		this.actions$.pipe(
			ofType(InfoActions.loadAgence),
			switchMap(() =>
				this.getAgenceUseCase.execute().pipe(
					map((agences) => {
						return InfoActions.loadAgenceSuccess({
							agences,
						});
					}),
					catchError((error) => {
						return of(
							InfoActions.loadAgenceFailure({
								error,
							})
						);
					})
				)
			)
		)
	);

	constructor(
		private actions$: Actions,
		private getContactUSUseCase: GetContactUSUseCase,
		private getAgenceUseCase: GetAgenceUseCase
	) {}
}
