import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as NotificationActions from '../actions/notification.actions';
import { GetNotificationsUseCase } from '../../application/use-cases/get-notifications.use-case';

@Injectable()
export class NotificationEffects {
	loadNotifications$ = createEffect(() =>
		this.actions$.pipe(
			ofType(NotificationActions.loadNotifications),
			switchMap(() =>
				this.getNotificationsUseCase.execute().pipe(
					map((notifications) =>
						NotificationActions.loadNotificationsSuccess({
							notifications,
						})
					),
					catchError((error) =>
						of(
							NotificationActions.loadNotificationsFailure({
								error,
							})
						)
					)
				)
			)
		)
	);

	constructor(
		private actions$: Actions,
		private getNotificationsUseCase: GetNotificationsUseCase
	) {}
}
