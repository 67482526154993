import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CoreBankingApiService } from '../../../infrastructure/api/core-banking-api.service';
import { ErrorHandlingService } from '../../../infrastructure/services/error-handling.service';
import { MessagesNotificationResponseDTO } from '../../dtos/notification/notification.dto';
import { INotificationService } from '../../../domain/interfaces/notifications-service.interface';
import { CORE_BANKING_API_ENDPOINTS } from '../../../infrastructure/constants/api-endpoints';

@Injectable({
	providedIn: 'root',
})
export class NotificationService implements INotificationService {
	constructor(
		private api: CoreBankingApiService,
		private errorHandler: ErrorHandlingService
	) {}

	getMessagesNotifications(): Observable<MessagesNotificationResponseDTO> {
		return this.api
			.post<MessagesNotificationResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.NOTIFICATIONS.GET_NOTIFICATIONS,
				{}
			)
			.pipe(
				catchError((error) =>
					this.errorHandler.handleError(
						error,
						'GET_MESSAGES_NOTIFICATIONS_FAILED',
						'Failed to get messages notifications'
					)
				)
			);
	}
}
