import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NotificationMapper } from '../../application/mappers/notification.mapper';
import { INotificationRepository } from '../../domain/repositories/notification.repository';
import { NotificationService } from '../../../core-banking/application/services/notification/notification.service';
import { NotificationCount } from '../../domain/entities/notification.entity';
import { CoreBankingError } from '../../../core-banking/domain/errors/core-banking-error';
import { NotificationError } from '../../domain/entities/error.entity';
import { adaptCoreBankingError } from '../adapters/error-adapter';

@Injectable({
	providedIn: 'root',
})
export class NotificationRepositoryImpl implements INotificationRepository {
	constructor(private notificationService: NotificationService) {}

	getAll(): Observable<NotificationCount> {
		return this.notificationService.getMessagesNotifications().pipe(
			map((response) =>
				NotificationMapper.toDomain(response.notifications[0])
			),
			catchError((error: unknown) => {
				if (error instanceof CoreBankingError) {
					const adaptedError = adaptCoreBankingError(error);
					return throwError(() =>
						NotificationError.fromError(adaptedError)
					);
				}
				return throwError(() => NotificationError.UNKNOWN_ERROR);
			})
		);
	}
}
