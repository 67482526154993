import {Observable} from "rxjs";
import {inject} from "@angular/core";
import {map} from "rxjs/operators";
import { IPrivacyPolicyRepository } from "../../domain/repositories/privacy-policy.repository";
import { PrivacyPolicy } from "../../domain/entities/privacy-policy.entity";
import { PrivacyPolicyService } from "../../../core-banking/application/services/privacy-policy/privacy-plicy.service";
import { PrivacyPolicyMapper } from "../../application/mappers/privacy-policy.mapper";

export class PrivacyPolicyRepositoryImpl implements IPrivacyPolicyRepository {
    private privacyPolicyService = inject(PrivacyPolicyService)

    getPrivacyPolicies(): Observable<PrivacyPolicy[]> {
        return this.privacyPolicyService.getPrivacyPolicy().pipe(
            map(response => {
                return response.records.map(policy => PrivacyPolicyMapper.mapToDomain(policy))
            })
        );
    }
}