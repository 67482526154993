import { createReducer, on } from '@ngrx/store';
import * as AuthActions from '../actions/auth.actions';
import { AuthSession } from '../../domain/entities/auth-session.entity';
import { UserContract } from '../../domain/entities/user-contract.entity';
import { LegalEntity } from '../../domain/entities/legal-entity.entity';
import { User } from '../../domain/entities/user.entity';

export interface AuthState {
	authSession: AuthSession | null;
	contracts: UserContract[] | null;
	user: User | null;
	legalEntity: LegalEntity[];
	loading: boolean;
	error: string | null;
	isAuthenticated: boolean;
	passwordForgotten: any;
	resetPassword: any;
	resendOTP: any;
	verifyOTP: any;
	termsAndConditions: any;
	resendActivateCode: any;
	isActivateCodeSend: boolean;
}

const initialState: AuthState = {
	authSession: null,
	contracts: null,
	user: null,
	legalEntity: [],
	loading: false,
	error: null,
	isAuthenticated: false,
	passwordForgotten: null,
	resetPassword: null,
	resendOTP: null,
	verifyOTP: null,
	termsAndConditions: null,
	resendActivateCode: null,
	isActivateCodeSend: false,
};

export const authReducer = createReducer(
	initialState,
	on(
		AuthActions.login,
		AuthActions.register,
		AuthActions.activateAccount,
		AuthActions.createPassword,
		AuthActions.logout,
		AuthActions.passwordForgotten,
		AuthActions.resetPassword,
		AuthActions.resendOTP,
		AuthActions.verifyOTP,
		AuthActions.termsAndConditions,
		AuthActions.resendActivateCode,
		(state) => ({
			...state,
			loading: true,
			error: null,
		})
	),
	on(AuthActions.loginSuccess, (state, { token, refreshToken, user }) => ({
		...state,
		authSession: { token, refreshToken, user },
		user,
		isAuthenticated: true,
		//loading: false,
		error: null,
	})),
	on(
		AuthActions.loginFailure,
		AuthActions.registerFailure,
		(state, { error }) => ({
			...state,
			loading: false,
			isAuthenticated: false,
			error,
		})
	),
	on(AuthActions.logoutSuccess, () => initialState),
	on(AuthActions.registerSuccess, (state) => ({
		...state,
		loading: false,
		error: null,
	})),
	on(
		AuthActions.registerFailure,
		AuthActions.resendActivateCodeFailure,
		(state, { error }) => ({
			...state,
			loading: false,
			error,
		})
	),
	on(AuthActions.activateAccountSuccess, (state) => ({
		...state,
		loading: false,
		error: null,
	})),
	on(AuthActions.activateAccountFailure, (state, { error }) => ({
		...state,
		loading: false,
		error,
	})),
	on(AuthActions.createPasswordSuccess, (state) => ({
		...state,
		loading: false,
		error: null,
	})),
	on(AuthActions.createPasswordFailure, (state, { error }) => ({
		...state,
		loading: false,
		error,
	})),
	on(AuthActions.getUserContractsSuccess, (state, { contracts }) => ({
		...state,
		contracts,
		loading: false,
		error: null,
	})),
	on(AuthActions.getUserContractsFailure, (state, { error }) => ({
		...state,
		loading: false,
		error,
	})),
	on(AuthActions.getLegalEntitySuccess, (state, { legalEntity }) => ({
		...state,
		legalEntity,
		loading: false,
		error: null,
	})),
	on(AuthActions.clearAuthError, (state) => ({
		...state,
		error: null,
	})),
	on(
		AuthActions.passwordForgottenSuccess,
		(state, { user_attributes, isUserExists, serviceKey }) => ({
			...state,
			passwordForgotten: { user_attributes, isUserExists, serviceKey },
			loading: false,
			error: null,
		})
	),
	on(AuthActions.passwordForgottenFailure, (state, { error }) => ({
		...state,
		loading: false,
		error,
	})),
	on(AuthActions.resendOTPSuccess, (state, { resendOTP }) => ({
		...state,
		resendOTP,
		loading: false,
		error: null,
	})),
	on(AuthActions.resendOTPFailure, (state, { error }) => ({
		...state,
		loading: false,
		error,
	})),
	on(AuthActions.resetPasswordSuccess, (state, { resetPassword }) => ({
		...state,
		resetPassword,
		loading: false,
		error: null,
	})),
	on(AuthActions.resetPasswordFailure, (state, { error }) => ({
		...state,
		loading: false,
		error,
	})),
	on(AuthActions.verifyOTPSuccess, (state, { serviceKey, userName }) => ({
		...state,
		verifyOTP: { serviceKey, userName },
		loading: false,
		error: null,
	})),
	on(AuthActions.verifyOTPFailure, (state, { error }) => ({
		...state,
		loading: false,
		error,
	})),
	on(
		AuthActions.termsAndConditionsSuccess,
		(state, { versionId, termsAndConditionsContent, contentTypeId }) => ({
			...state,
			termsAndConditions: {
				versionId,
				termsAndConditionsContent,
				contentTypeId,
			},
			loading: false,
			error: null,
		})
	),
	on(AuthActions.termsAndConditionsFailure, (state, { error }) => ({
		...state,
		loading: false,
		error,
	})),
	on(AuthActions.resendActivateCodeSuccess, (state) => ({
		...state,
		isActivateCodeSend: true,
		loading: false,
		error: null,
	}))
);
