import { inject, Injectable } from '@angular/core';
import { ITransactionRepository } from '../../domain/repositories/transaction.repository';
import { Observable } from 'rxjs';
import { TransactionRequestDTO } from '../dtos/transaction.dto';
import { LoanTransaction } from '../../domain/entities/loan-transaction.entity';

@Injectable({ providedIn: 'root' })
export class GetLoanAccountTransactionUseCase {
	transactionService = inject(ITransactionRepository);

	execute(request: TransactionRequestDTO): Observable<LoanTransaction[]> {
		return this.transactionService.getLoanTransactions(request);
	}
}
