import { CoreBankingError } from '../../../core-banking/domain/errors/core-banking-error';
import {BaseError} from "../../../../shared/errors/base-error";

export function adaptCoreBankingError(error: CoreBankingError): BaseError {
    return {
        code: error.code,
        message: error.message,
        status: error.httpStatusCode,
        originalError: error
    };
}
