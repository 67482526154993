import { Routes } from '@angular/router';
import { PageNotFoundComponent } from './presentation/shared/components/page-not-found/page-not-found.component';
import { AuthLayoutComponent } from './presentation/layouts/auth-layout/auth-layout.component';
import { DashboardLayoutComponent } from './presentation/layouts/dashboard-layout/dashboard-layout.component';
import { HomeLayoutComponent } from './presentation/layouts/home-layout/home-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';

export const routes: Routes = [
	{
		path: '',
		redirectTo: 'home',
		pathMatch: 'full',
	},
	{
		path: 'auth',
		component: AuthLayoutComponent,
		loadChildren: () =>
			import(
				'./presentation/features/authentication/authentication.module'
			).then((m) => m.AuthenticationModule),
	},
	{
		path: 'home',
		component: HomeLayoutComponent,
		loadChildren: () =>
			import('./presentation/features/home/home.module').then(
				(m) => m.HomeModule
			),
		canActivate: [AuthGuard],
	},
	{
		path: 'account',
		component: DashboardLayoutComponent,
		loadChildren: () =>
			import('./presentation/features/account/account.module').then(
				(m) => m.AccountModule
			),
		canActivate: [AuthGuard],
	},
	{
		path: 'transfer',
		component: DashboardLayoutComponent,
		loadChildren: () =>
			import('./presentation/features/transfer/transfer.module').then(
				(m) => m.TransferModule
			),
		canActivate: [AuthGuard],
	},

	{
		path: '',
		component: DashboardLayoutComponent,
		loadChildren: () =>
			import(
				'./presentation/features/bank-and-wallet/bank-and-wallet.module'
			).then((m) => m.BankAndWalletModule),
		canActivate: [AuthGuard],
	},
	{
		path: '',
		component: DashboardLayoutComponent,
		loadChildren: () =>
			import('./presentation/features/info/info.module').then(
				(m) => m.InfoModule
			),
		canActivate: [AuthGuard],
	},
	{
		path: 'message',
		component: DashboardLayoutComponent,
		loadChildren: () =>
			import('./presentation/features/message/message.module').then(
				(m) => m.MessageModule
			),
		canActivate: [AuthGuard],
	},
	{
		path: 'beneficiary',
		component: DashboardLayoutComponent,
		loadChildren: () =>
			import('./presentation/features/beneficiary/beneficiary.module').then(
				(m) => m.BeneficiaryModule
			),
		canActivate: [AuthGuard],
	},
	{
		path: 'shared-components',
		component: DashboardLayoutComponent,
		loadChildren: () =>
			import(
				'./presentation/features/shared-components/shared-components.module'
			).then((m) => m.SharedComponentsModule),
	},
	{
		path: '**',
		component: PageNotFoundComponent,
	},
];
