import { Discussion } from "../../domain/entities/discussion.entity";
import { CreateDiscussionResponseDTO, GetDiscussionsResponseDTO, DiscussionDTO, DiscussionResponseDTO } from "../dtos/discussion.dto";

export class DiscussionMapper {
    static toDTO(discussion: Discussion): DiscussionDTO {
        return {
            id: discussion.id,
            requestid: discussion.requestid,
            customer_id: discussion.customer_id,
            username: discussion.username,
            totalAttachments: discussion.totalAttachments,
            firstMessage: discussion.firstMessage,
            requestcategory_id: discussion.requestcategory_id,
            recentMsgDate: discussion.recentMsgDate,
            priority: discussion.priority,
            isPriorityMessage: discussion.isPriorityMessage,
            softdeleteflag: discussion.softdeleteflag,    
            unreadmsgs: discussion.unreadmsgs,
            priorityCount: discussion.priorityCount,
            msgids: discussion.msgids,
            requestsubject: discussion.requestsubject,
            status_id: discussion.status_id,
            totalmsgs: discussion.totalmsgs,
            requestCreatedDate: discussion.requestCreatedDate,
            statusIdentifier: discussion.statusIdentifier,
            readmsgs: discussion.readmsgs,
            messagedescription: discussion.messagedescription
        };
    }

    static toDomain(dto: DiscussionDTO): Discussion {
        return new Discussion(
            dto.id,
            dto.requestid,
            dto.customer_id,
            dto.username,
            dto.totalAttachments,
            dto.firstMessage,
            dto.requestcategory_id,
            dto.recentMsgDate,
            dto.priority,
            dto.isPriorityMessage,
            dto.softdeleteflag,
            dto.unreadmsgs,
            dto.priorityCount,
            dto.msgids,
            dto.requestsubject,
            dto.status_id,
            dto.totalmsgs,
            dto.requestCreatedDate,
            dto.statusIdentifier,
            dto.readmsgs,
            dto.messagedescription
        )
    }

    static createResponseDTO(data: any): CreateDiscussionResponseDTO {
        return {
            opstatus: data.opstatus
        }
    }

    static getResponseDTO(data: any): GetDiscussionsResponseDTO {
        return {
            customerrequests_view: data.customerrequests_view,
            opstatus: data.opstatus,
            httpStatusCode: data.httpStatusCode
        }
    }

    static discussionResponseDTO(data: any): DiscussionResponseDTO {
        return {
            requestid: data.requestid,
            dbpErrCode: data.dbpErrCode,
            dbpErrMsg: data.dbpErrMsg,
            validationError: data.validationError,
            operationRecord: {
                hardDelete: data.hardDelete,
                softDelete: data.softDelete,
                operationStatusCodeParams: data.operationStatusCodeParams
            },
            opstatus: data.opstatus,
            httpStatusCode: data.httpStatusCode
        }
    }

}

