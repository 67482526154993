import { Inject, Injectable } from "@angular/core";
import { IDiscussionRepository } from "../../domain/repositories/discussion.repository";
import { Observable } from "rxjs";
import { DiscussionCategory } from "../../domain/entities/discussion-category.entity";

@Injectable({
    providedIn: 'root'
})
export class GetDiscussionCategoriesUseCase {
    constructor(@Inject('IDiscussionRepository') private discussionRepository: IDiscussionRepository) {}

    execute(): Observable<DiscussionCategory[]> {
        return this.discussionRepository.getCategories();
    }
}
