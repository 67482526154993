import { PrivacyPolicy } from "../../domain/entities/privacy-policy.entity";
import { PrivacyPolicyDTO } from "../dtos/privacy-policy.dto";

export class PrivacyPolicyMapper {
    static mapToDTO(privacyPolicy: PrivacyPolicy): PrivacyPolicyDTO {
        return {
            id : privacyPolicy.id,
            Channel_id: privacyPolicy.Channel_id,
            Status_id: privacyPolicy.Status_id,
            Description : privacyPolicy.Description,
            createdby: privacyPolicy.createdby,
            modifiedby: privacyPolicy.modifiedby, 
            softdeleteflag: privacyPolicy.softdeleteflag
        }
    }

    static mapToDomain(privacyPolicyDTO: PrivacyPolicyDTO): PrivacyPolicy {
        return new PrivacyPolicy(
            privacyPolicyDTO.id,
            privacyPolicyDTO.Channel_id,
            privacyPolicyDTO.Status_id,
            privacyPolicyDTO.Description,
            privacyPolicyDTO.createdby,
            privacyPolicyDTO.modifiedby, 
            privacyPolicyDTO.softdeleteflag
        );
    }
}