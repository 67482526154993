import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAuthRepository } from '../../domain/repositories/auth.repository';
import { ResendOTPDTO } from '../dtos/authentication.dto';

@Injectable({
	providedIn: 'root',
})
export class ResendOTPUseCase {
	constructor(
		@Inject('IAuthRepository') private authRepository: IAuthRepository
	) {}

	execute(data: ResendOTPDTO): Observable<any> {
		return this.authRepository.resendOTP(data);
	}
}
