export class User {
  constructor(
      public id: string,
      public firstName: string,
      public lastName: string,
      public legalEntityId: string,
      public customerId?: string,
      public email?: string,
      public username?: string,
      public phoneNumber?: string,
      public activationCode?: string,
      public isActivationCodeValid?: boolean,
      public contractId?: string,
  ) {}
}
