import { createReducer, on } from "@ngrx/store";
import { FaqCategory } from "../../domain/entities/faqs.entity";
import { loadFaqs, loadFaqsFailure, loadFaqsSuccess } from "../actions/faq.actions";


export interface FaqState {
    categories: FaqCategory[];
    loading: boolean;
    error: any;
}

export const initialState: FaqState = {
    categories: [],
    loading: false,
    error: null,
};

export const faqReducer = createReducer(
    initialState,
    on(loadFaqs, state => ({ ...state, loading: true })),
    on(loadFaqsSuccess, (state, { categories }) => ({
        ...state,
        categories,
        loading: false,
        error: null
    })),
    on(loadFaqsFailure, (state, { error }) => ({
        ...state,
        error,
        loading: false
    }))
);
