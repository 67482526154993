import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {provideEffects} from "@ngrx/effects";
import {PrivacyPolicyEffects} from "./state/effects/privacy-policy.effects";
import { PrivacyPolicyRepositoryImpl } from './infrastructure/repositories/privacy-policy.repository.impl';

@NgModule({
    imports: [
        CommonModule,
    ],
    providers: [
        { provide: 'IPrivacyPolicyRepository', useClass: PrivacyPolicyRepositoryImpl },
        provideEffects(PrivacyPolicyEffects)
    ]
})
export class CorePrivacyPolicyModule { }
