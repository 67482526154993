import {IBeneficiaryRepository} from "../../domain/repositories/beneficiary.repository";
import {Beneficiary} from "../../domain/entities/beneficiary.entity";
import {Observable} from "rxjs";
import {Inject, Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class GetBeneficiariesUseCase {
    constructor(@Inject('IBeneficiaryRepository') private beneficiaryRepository: IBeneficiaryRepository) {}

     execute(): Observable<Beneficiary[]> {
        return this.beneficiaryRepository.getAll();
    }
}
