import { Inject, Injectable } from "@angular/core";
import { IDiscussionRepository } from "../../domain/repositories/discussion.repository";
import { Observable } from "rxjs";
import { CreateDiscussionResponseDTO, DiscussionDTO } from "../dtos/discussion.dto";

@Injectable({
    providedIn: 'root'
})
export class CreateDiscussionUseCase {
    constructor(@Inject('IDiscussionRepository') private discussionRepository: IDiscussionRepository) {}

    execute(request: DiscussionDTO): Observable<CreateDiscussionResponseDTO> {
        return this.discussionRepository.create(request);
    }
}
