import { Injectable } from '@angular/core';
import { forkJoin, Observable, switchMap, tap, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CoreBankingApiService } from '../../../infrastructure/api/core-banking-api.service';
import { IAuthService } from '../../../domain/interfaces/auth-service.interface';
import { SuccessResponse } from '../../../shared/types/api-responses';
import {
	TermsAndConditionsResponseDTO,
	UserRegistrationRequestDTO,
	UserRegistrationResponseDTO,
} from '../../dtos/auth/user-registration.dto';
import {
	ActivateAccountRequestDTO,
	ActivateAccountResponseDTO,
	ResendActivateCodeRequestDTO,
	ResendActivateCodeResponseDTO,
} from '../../dtos/auth/user-active-account.dto';
import {
	LoginCredentialsDTO,
	LoginResponseDTO,
} from '../../dtos/auth/user-login.dto';
import { CreatePasswordRequestDTO } from '../../dtos/auth/create-password.dto';
import { ErrorHandlingService } from '../../../infrastructure/services/error-handling.service';
import { UserContractsResponseDTO } from '../../dtos/auth/user-contract.dto';
import { CoreBankingError } from '../../../domain/errors/core-banking-error';
import {
	RequestResendOTPDTO,
	PasswordForgottenRequestDTO,
	RequestVerifyOTPDTO,
	ResetPasswordRequestDTO,
} from '../../dtos/auth/reset-password.dto';
import { LegalEntityListResponseDTO } from '../../dtos/company/legal-entity.dto';
import { CORE_BANKING_API_ENDPOINTS } from '../../../infrastructure/constants/api-endpoints';
import { CookieService } from 'ngx-cookie-service';
import { CompanyService } from '../company/company.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService implements IAuthService {
	constructor(
		private api: CoreBankingApiService,
		private errorHandler: ErrorHandlingService,
		private companyService: CompanyService,
		private cookieService: CookieService
	) {}

	login(credentials: LoginCredentialsDTO): Observable<LoginResponseDTO> {
		return this.api
			.login<LoginResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.AUTH.LOGIN,
				credentials
			)
			.pipe(
				switchMap((loginResponse: LoginResponseDTO) => {
					return this.companyService.getLegalEntities().pipe(
						switchMap((legalEntities) => {
							const claimsToken = loginResponse?.claims_token?.value;
							const legalEntityId =
								loginResponse?.profile?.user_attributes?.legalEntityId;
							return this.companyService
								.updateCurrentLegalEntity(claimsToken, legalEntityId)
								.pipe(
									switchMap(() => {
										// Feature permissions
										return this.api.postKony(
											CORE_BANKING_API_ENDPOINTS.AUTH
												.FEATURE_PERMISSIONS,
											null,
											claimsToken
										);
									})
								);
						}),
						map(() => loginResponse)
					);
				}),
				catchError((error) => {
					if (error.status && error.status === 401) {
						throw new CoreBankingError(
							'USER_NOT_FOUND',
							"Échec de l'authentification",
							error.status,
							error.status,
							error
						);
					}
					if (error instanceof CoreBankingError) {
						return throwError(() => error);
					}
					return this.errorHandler.handleError(
						error,
						'LOGIN_FAILED',
						'Failed to login'
					);
				})
			);
	}

	register(
		user: UserRegistrationRequestDTO
	): Observable<UserRegistrationResponseDTO> {
		return this.api
			.register<UserRegistrationResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.AUTH.REGISTER,
				user
			)
			.pipe(
				map((response) => {
					if (
						response.isUserExists === 'true' &&
						response.isActivationCodeSent === 'true'
					) {
						return response;
					} else if (response.isUserExists === 'false') {
						throw new CoreBankingError(
							'USER_NOT_EXIST',
							"L'utilisateur n'existe pas",
							0,
							404,
							response
						);
					} else if (
						response.isUserExists === 'true' &&
						response.isUserEnrolled === 'true'
					) {
						throw new CoreBankingError(
							'USER_ALREADY_ENROLLED',
							"L'utilisateur est déjà inscrit",
							0,
							409,
							response
						);
					} else {
						throw new CoreBankingError(
							'INVALID_REGISTRATION_RESPONSE',
							"La réponse d'enregistrement est invalide",
							0,
							400,
							response
						);
					}
				}),
				catchError((error) => {
					if (error instanceof CoreBankingError) {
						return throwError(() => error);
					}
					return this.errorHandler.handleError(
						error,
						'REGISTRATION_FAILED',
						'Failed to register user'
					);
				})
			);
	}

	getLegalEntities(): Observable<LegalEntityListResponseDTO> {
		return this.api
			.getLegalEntities<LegalEntityListResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.LEGAL_ENTITIES.GET_LEGAL_ENTITIES
			)
			.pipe(
				catchError((error) =>
					this.errorHandler.handleError(
						error,
						'GET_LEGAL_ENTITIES_FAILED',
						'Failed to get legal entities'
					)
				)
			);
	}

	activateAccount(
		data: ActivateAccountRequestDTO
	): Observable<ActivateAccountResponseDTO> {
		return this.api
			.register<ActivateAccountResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.AUTH.ACTIVATE_ACCOUNT,
				data
			)
			.pipe(
				map((response) => {
					if (response && !response.dbpErrCode) {
						return response;
					}
					const errorMap: Record<
						number,
						{ code: string; message: string }
					> = {
						10741: {
							code: 'INVALID_ACTIVATION_CODE_VALIDATION',
							message: "Nombre d'essais de réinitialisation dépassé",
						},
						10747: {
							code: 'MAX_ATTEMPTS_REACHED',
							message: "Nombre d'essais de réinitialisation dépassé",
						},
					};

					const error =
						response.dbpErrCode && errorMap[response.dbpErrCode]
							? errorMap[response.dbpErrCode]
							: {
									code: 'USER_NOT_FOUND',
									message: 'Information invalide',
							  };

					throw new CoreBankingError(
						error.code,
						error.message,
						0,
						400,
						response
					);
				}),
				catchError((error) =>
					error instanceof CoreBankingError
						? throwError(() => error)
						: this.errorHandler.handleError(
								error,
								'ACTIVATION_FAILED',
								'Failed to activate account'
						  )
				)
			);
	}

	createPassword(data: CreatePasswordRequestDTO): Observable<SuccessResponse> {
		return this.api
			.register<SuccessResponse>(
				CORE_BANKING_API_ENDPOINTS.AUTH.CREATE_PASSWORD,
				data
			)
			.pipe(
				catchError((error) =>
					this.errorHandler.handleError(
						error,
						'CREATE_PASSWORD_FAILED',
						'Failed to create password'
					)
				)
			);
	}

	logout(): Observable<void> {
		return this.api
			.login<void>(CORE_BANKING_API_ENDPOINTS.AUTH.LOGOUT, {})
			.pipe(
				catchError((error) =>
					this.errorHandler.handleError(
						error,
						'LOGOUT_FAILED',
						'Failed to logout'
					)
				)
			);
	}

	getUserContracts(): Observable<UserContractsResponseDTO> {
		return this.api
			.post<UserContractsResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.AUTH.GET_USER_CONTRACTS,
				{}
			)
			.pipe(
				catchError((error) =>
					this.errorHandler.handleError(
						error,
						'GET_USER_CONTRACTS_FAILED',
						'Failed to get user contracts'
					)
				)
			);
	}

	refreshToken(refreshToken: string | null): Observable<string> {
		return this.api
			.post<any>(CORE_BANKING_API_ENDPOINTS.AUTH.REFRESH_TOKEN, {
				refreshToken,
			})
			.pipe(
				catchError((error) =>
					this.errorHandler.handleError(
						error,
						'REFRESH_TOKEN_FAILED',
						'Failed to refresh token'
					)
				)
			);
	}

	postVerifyCoreUser(data: PasswordForgottenRequestDTO): Observable<any> {
		return this.api
			.postVerifyCoreUser<any>(
				CORE_BANKING_API_ENDPOINTS.AUTH.VERIFY_CORE_USER,
				data
			)
			.pipe(
				map((response) => {
					if (response && response.isUserExists === 'true') {
						return response;
					} else {
						throw new CoreBankingError(
							'USER_NOT_FOUND',
							'Information invalide',
							0,
							400,
							response
						);
					}
				}),
				catchError((error) => {
					if (error instanceof CoreBankingError) {
						return throwError(() => error);
					}
					return this.errorHandler.handleError(
						error,
						'RESET_PASSWORD_ERROR',
						'Failed to verify core user'
					);
				})
			);
	}

	requestResetPasswordOTP(data: RequestResendOTPDTO): Observable<any> {
		return this.api
			.requestResetPasswordOTP<any>(
				CORE_BANKING_API_ENDPOINTS.AUTH.REQUEST_RESET_PASSWORD_OTP,
				data
			)
			.pipe(
				map((response) => {
					if (response && !response.dbpErrCode) {
						return response;
					} else if (response.dbpErrCode === '10544') {
						throw new CoreBankingError(
							'MAX_RESEND_OTP_ATTEMPTS_REACHED',
							"Nombre d'essais de réinitialisation dépassé",
							0,
							429,
							response
						);
					} else {
						throw new CoreBankingError(
							'INVALID_INFORMATION',
							'Information invalide',
							0,
							400,
							response
						);
					}
				}),
				catchError((error) => {
					if (error instanceof CoreBankingError) {
						return throwError(() => error);
					}
					return this.errorHandler.handleError(
						error,
						'RESET_PASSWORD_ERROR',
						'Failed to verify core user'
					);
				})
			);
	}

	verifyOTP(data: RequestVerifyOTPDTO): Observable<any> {
		return this.api
			.requestResetPasswordOTP<any>(
				CORE_BANKING_API_ENDPOINTS.AUTH.VERIFY_OTP,
				data
			)
			.pipe(
				map((response) => {
					if (response && response.isOtpVerified === 'true') {
						return response;
					} else {
						throw new CoreBankingError(
							'INVALID_OTP_INFORMATION',
							'Information invalide',
							0,
							400,
							response
						);
					}
				}),
				catchError((error) => {
					if (error instanceof CoreBankingError) {
						return throwError(() => error);
					}
					return this.errorHandler.handleError(
						error,
						'RESET_PASSWORD_ERROR',
						'Failed to verify core user'
					);
				})
			);
	}

	resetPassword(data: ResetPasswordRequestDTO) {
		return this.api
			.resetPassword<any>(
				CORE_BANKING_API_ENDPOINTS.AUTH.RESET_PASSWORD,
				data
			)
			.pipe(
				map((response) => {
					if (response && !response.dbpErrCode) {
						return response;
					} else if (response.dbpErrCode === 10134) {
						throw new CoreBankingError(
							'PASSWORD_ALREADY_USED',
							'Password is already present in the previous 5 passwords',
							0,
							404,
							response
						);
					} else {
						throw new CoreBankingError(
							'RESET_PASSWORD_ERROR',
							'Information invalide',
							0,
							404,
							response
						);
					}
				}),
				catchError((error) => {
					if (error instanceof CoreBankingError) {
						return throwError(() => error);
					}
					return this.errorHandler.handleError(
						error,
						'RESET_PASSWORD_ERROR',
						'Failed to verify core user'
					);
				})
			);
	}

	getTermsAndConditions(): Observable<TermsAndConditionsResponseDTO> {
		const currentLegalEntityId = this.cookieService.get('legal_entity') || '';
		return this.api
			.post<TermsAndConditionsResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.USER.TERMS_AND_CONDITIONS,
				{
					languageCode: 'en-US',
					termsAndConditionsCode: 'Common_TnC',
					legalEntityId: currentLegalEntityId,
				}
			)
			.pipe(
				map((response) => {
					if (response && !response.dbpErrCode) {
						return response;
					} else {
						throw new CoreBankingError(
							'TERMS_AND_CONDITIONS_ERROR',
							'Information invalide',
							0,
							400,
							response
						);
					}
				}),
				catchError((error) =>
					this.errorHandler.handleError(
						error,
						'GET_TERMS_AND_CONDITIONS_FAILED',
						'Failed to get terms and conditions'
					)
				)
			);
	}

	resendActivateCode(data: ResendActivateCodeRequestDTO) {
		return this.api
			.resendActivateCode<ResendActivateCodeResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.AUTH.RESEND_ACTIVATE_CODE,
				data
			)
			.pipe(
				map((response) => {
					if (response && !response.errcode) {
						return response;
					} else {
						throw new CoreBankingError(
							'RESEND_ACTIVATE_CODE_ERROR',
							'Information invalide',
							0,
							404,
							response
						);
					}
				}),
				catchError((error) => {
					if (error instanceof CoreBankingError) {
						return throwError(() => error);
					}
					return this.errorHandler.handleError(
						error,
						'RESEND_ACTIVATE_CODE_FAILED',
						'Failed to verify core user'
					);
				})
			);
	}
}
