import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoRepositoryImpl } from './infrastructure/repositories/info.repository.impl';
import { GetContactUSUseCase } from './application/use-cases/get-contact-us.use-case';
import { GetAgenceUseCase } from './application/use-cases/get-agences.use-case';

@NgModule({
	imports: [CommonModule],
	providers: [
		{
			provide: 'IInfoRepository',
			useClass: InfoRepositoryImpl,
		},
		GetContactUSUseCase,
		GetAgenceUseCase,
	],
})
export class CoreInfoModule {}
