import { Inject, Injectable } from '@angular/core';
import {
	HttpInterceptor,
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, interval } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { AuthService } from '../../../core-banking/application/services/auth/auth.service';
import { AuthTokenService } from '../../application/services/auth-token.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	private isRefreshing = false;
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
		null
	);

	constructor(
		private tokenService: AuthTokenService,
		private authService: AuthService,
		private router: Router
	) {
		this.checkTokenRegularly(); // Démarre la vérification dès que le service est créé
	}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		const token = this.tokenService.getToken();
		if (token) {
			request = this.addToken(request, token);
		}

		return next.handle(request).pipe(
			catchError((error) => {
				return throwError(error);
				/*if (error instanceof HttpErrorResponse && error.status === 401) {
                    console.log('Error 401');
                    return this.handle401Error(request, next);
                } else {
                    return throwError(error);
                }*/
			})
		);
	}

	private addToken(request: HttpRequest<any>, token: string) {
		return request.clone({
			setHeaders: {
				'Content-Type': 'application/json',
				'X-Kony-Authorization': `${token}`,
			},
		});
	}

	private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
		if (!this.isRefreshing) {
			this.isRefreshing = true;
			this.refreshTokenSubject.next(null);

			const refreshToken = this.tokenService.getRefreshToken();
			return this.authService.refreshToken(refreshToken).pipe(
				switchMap((token: any) => {
					this.isRefreshing = false;
					this.refreshTokenSubject.next(token);
					return next.handle(this.addToken(request, token));
				}),
				catchError((err) => {
					this.isRefreshing = false;
					this.authService.logout();
					return throwError(err);
				})
			);
		} else {
			return this.refreshTokenSubject.pipe(
				filter((token) => token != null),
				take(1),
				switchMap((jwt) => {
					return next.handle(this.addToken(request, jwt));
				})
			);
		}
	}

	// Vérifie régulièrement le token et redirige si nécessaire
	checkTokenRegularly() {
		// Vérifie toutes les 10 secondes (10000 ms)
		interval(10000).subscribe(() => {
			const token = this.tokenService.getToken();

			const isLogged = this.tokenService.isLogged();
			if (!isLogged && token) {
				this.tokenService.removeTokens();
				this.router.navigate(['/auth/login']);
				return false;
			}
			return false;
		});
	}
}
