import { createReducer, on } from '@ngrx/store';
import * as BeneficiaryActions from '../actions/beneficiary.actions';
import { Beneficiary } from '../../domain/entities/beneficiary.entity';

export interface BeneficiaryState {
    beneficiaries: Beneficiary[];
    loading: boolean;
    error: any;
    payeeNames: { [accountNumber: string]: string };
    response: any;
}

export const initialState: BeneficiaryState = {
    beneficiaries: [],
    loading: false,
    error: null,
    payeeNames: {},
    response: null
};

export const beneficiaryReducer = createReducer(
    initialState,
    on(BeneficiaryActions.loadBeneficiaries, state => ({ ...state, loading: true })),
    on(BeneficiaryActions.loadBeneficiariesSuccess, (state, { beneficiaries }) => ({
        ...state,
        beneficiaries,
        loading: false,
        error: null
    })),
    on(BeneficiaryActions.loadBeneficiariesFailure, (state, { error }) => ({
        ...state,
        error,
        loading: false
    })),

    on(BeneficiaryActions.createBeneficiary, state => ({ ...state, loading: true })),
    on(BeneficiaryActions.createBeneficiarySuccess, (state, { beneficiary }) => ({
        ...state,
        response: beneficiary,
        loading: false,
        error: null
    })),
    on(BeneficiaryActions.createBeneficiaryFailure, (state, { error }) => ({
        ...state,
        error,
        loading: false
    })),

    on(BeneficiaryActions.updateBeneficiary, state => ({ ...state, loading: true })),
    on(BeneficiaryActions.updateBeneficiarySuccess, (state, { beneficiary }) => ({
        ...state,
        response: beneficiary,
        loading: false,
        error: null
    })),
    on(BeneficiaryActions.updateBeneficiaryFailure, (state, { error }) => ({
        ...state,
        error,
        loading: false
    })),

    on(BeneficiaryActions.deleteBeneficiary, state => ({ ...state, loading: true })),
    on(BeneficiaryActions.deleteBeneficiarySuccess, (state, { beneficiary }) => ({
        ...state,
        beneficiaries: state.beneficiaries.filter(b => b.id !== beneficiary.id),
        loading: false,
        error: null
    })),
    on(BeneficiaryActions.deleteBeneficiaryFailure, (state, { error }) => ({
        ...state,
        error,
        loading: false
    })),

    on(BeneficiaryActions.getPayeeName, state => ({ ...state, loading: true })),
    on(BeneficiaryActions.getPayeeNameSuccess, (state, { accountNumber, payeeName }) => ({
        ...state,
        payeeNames: { ...state.payeeNames, [accountNumber]: payeeName },
        loading: false,
        error: null
    })),
    on(BeneficiaryActions.getPayeeNameFailure, (state, { error }) => ({
        ...state,
        error,
        loading: false
    }))
);
