import { Injectable } from "@angular/core";
import { IDiscussionRepository } from "../../domain/repositories/discussion.repository";
import { catchError, map, Observable, throwError } from "rxjs";
import { DiscussionService } from "../../../core-banking/application/services/discussion/discussion.service";
import { DiscussionCategory } from "../../domain/entities/discussion-category.entity";
import { DiscussionCategoryMapper } from "../../application/mappers/discussion-category.mapper";
import {
  CreateDiscussionResponseDTO,
  DiscussionDTO,
  DiscussionResponseDTO
} from "../../application/dtos/discussion.dto";
import { DiscussionMapper } from "../../application/mappers/discussion.mapper";
import { Discussion } from "../../domain/entities/discussion.entity";
import { adaptCoreBankingError } from "../adapters/error-adapter";
import { CoreBankingError, InvalidParametersException } from "../../../core-banking/domain/errors/core-banking-error";
import { DiscussionError } from "../../domain/entities/error.entity";
import { Logger } from "../../../../shared/helpers/logger-helper";

@Injectable({
  providedIn: "root",
})
export class DiscussionRepositoryImpl implements IDiscussionRepository {
  constructor(private discussionService: DiscussionService) {}

  getCategories(): Observable<DiscussionCategory[]> {
    return this.discussionService.getDiscussionCategories().pipe(
      map((response) =>
        response.requestcategory.map((dto) =>
          DiscussionCategoryMapper.toDomain(dto)
        )
      ),
      catchError((error: unknown) => {
        if (error instanceof CoreBankingError) {
          const adaptedError = adaptCoreBankingError(error);
          return throwError(() => DiscussionError.fromError(adaptedError));
        }
        return throwError(() => DiscussionError.GET_CATEGORIES_ERROR);
      })
    );
  }

  create(
    request: DiscussionDTO
  ): Observable<CreateDiscussionResponseDTO> {
    // let data = {...request, authToken: this.authTokenService.getToken() || '' };
    Logger.info("DiscussionRepositoryImpl request", request);
    return this.discussionService
      .createDiscussion(request)
      .pipe(
        map((response) =>
          DiscussionMapper.createResponseDTO(response)
        ),  
        catchError((error: unknown) => {
          if (error instanceof CoreBankingError) {
            const adaptedError = adaptCoreBankingError(error);
            return throwError(() => DiscussionError.fromError(adaptedError));
          }
          else if (error instanceof InvalidParametersException) {
            return throwError(() => error);
          }
          return throwError(() => DiscussionError.CREATE_DISCUSSION_ERROR);
        })
      );
  }

  getDiscussions(): Observable<Discussion[]> {
    return this.discussionService
      .getDiscussions()
      .pipe(
        map((response) => {
          Logger.info("Get discussion infra repo: ", response);
          
          return response.customerrequests_view.map((dto) =>
            DiscussionMapper.toDomain(dto)
          )
        }
        )
      );
  }

  activateDiscussion(discussionId: string): Observable<DiscussionResponseDTO> {
    return this.discussionService.updateDiscussion({
      requestid: discussionId,
      softdelete: "false"
    }).pipe(
      map((response) => {
        return DiscussionMapper.discussionResponseDTO(response)
      }),
      catchError((error: unknown) => {
        return throwError(() => error);
      })
    )
  }
  
  deactivateDiscussion(discussionId: string): Observable<DiscussionResponseDTO> {
    return this.discussionService.updateDiscussion({
      requestid: discussionId,
      softdelete: "true"
    }).pipe(
      map((response) => {
        return DiscussionMapper.discussionResponseDTO(response)
      }),
      catchError((error: unknown) => {
        return throwError(() => error);
      })
    )
  }

  deleteDiscussion(discussionId: string): Observable<DiscussionResponseDTO> {
    return this.discussionService.updateDiscussion({
      requestid: discussionId,
      harddelete: "true"
    }).pipe(
      map((response) => {
        return DiscussionMapper.discussionResponseDTO(response)
      }),
      catchError((error: unknown) => {
        return throwError(() => error);
      })
    )
  }
}
