import { Inject, Injectable } from "@angular/core";
import { IDiscussionRepository } from "../../domain/repositories/discussion.repository";
import { Observable } from "rxjs";
import { Discussion } from "../../domain/entities/discussion.entity";
import { GetDiscussionsResponseDTO } from "../dtos/discussion.dto";

@Injectable({
    providedIn: 'root'
})
export class GetAllDiscussionsUseCase {
    constructor(@Inject('IDiscussionRepository') private discussionRepository: IDiscussionRepository) {}

    
    execute(): Observable<Discussion[]> {
        return this.discussionRepository.getDiscussions();
    }
}
