import {Actions, createEffect, ofType} from "@ngrx/effects";
import {AccountActions} from "./account.actions";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {inject, Injectable} from "@angular/core";
import {IAccountRepository} from "../domain/repositories/account.repository";
import {GetAccountUseCase} from "../application/use-cases/get-account.use-case";


@Injectable()
export class AccountEffects {

    loadAccounts = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(AccountActions.loadAccounts),
                switchMap(() => {
                    return this.getAccountUseCase.execute().pipe(
                        map((response) => AccountActions.loadAccountSuccess({accounts: response})),
                        catchError((error) => {
                            return of(AccountActions.loadAccountFailure({error}));
                        })
                    );
                })
            );
        }
    );
    constructor(
        private actions$: Actions,
        private getAccountUseCase: GetAccountUseCase
    ) {}
}