import { DomainError } from '../../../../shared/errors/DomainError';

export class NotificationError extends DomainError {
	static readonly UNKNOWN_ERROR = new NotificationError(
		'NOTIFICATION.ERRORS.UNKNOWN_ERROR',
		'Une erreur inconnue est survenue',
		500
	);

	constructor(
		code: string,
		message: string,
		status?: number,
		originalError?: any
	) {
		super(code, message, status, originalError);
	}

	static fromError(error: {
		code: string;
		message: string;
		httpStatusCode?: number;
	}): NotificationError {
		switch (error.code) {
			case 'NOTIFICATION_NOT_FOUND':
				return NotificationError.UNKNOWN_ERROR;
			default:
				return new NotificationError(
					'NOTIFICATION.ERRORS.EXTERNAL_ERROR',
					error.message,
					error.httpStatusCode || 500,
					error
				);
		}
	}
}
