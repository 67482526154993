import {Transaction} from "../../domain/entities/transaction.entity";
import {TransactionDTO} from "../dtos/transaction.dto";

export class TransactionMapper {
    static toDomain(transaction: TransactionDTO): Transaction {
        return new Transaction(
            transaction.Id,
            transaction.amount,
            transaction.description,
            transaction.frequencyStartDate,
            transaction.frequencyType,
            transaction.fromAccountName,
            transaction.fromAccountNumber,
            transaction.scheduledDate,
            transaction.toAccountName,
            transaction.toAccountNumber,
            transaction.transactionType,
            transaction.payeeCurrency,
            transaction.transactionId,
            transaction.postedDate,
            transaction.statusDescription,
            transaction.valueDateTime,
            transaction.transactionsNotes
        );
    }

    toDTO(transaction: Transaction): TransactionDTO {
        return <TransactionDTO>{
            Id: transaction.id,
            amount: transaction.amount,
            description: transaction.description,
            frequencyStartDate: transaction.frequencyStartDate,
            frequencyType: transaction.frequencyType,
            fromAccountName: transaction.fromAccountName,
            fromAccountNumber: transaction.fromAccountNumber,
            scheduledDate: transaction.scheduledDate,
            toAccountName: transaction.toAccountName,
            toAccountNumber: transaction.toAccountNumber,
            transactionType: transaction.transactionType,
            payeeCurrency: transaction.payeeCurrency,
            postedDate: transaction.postedDate,
            statusDescription: transaction.statusDescription,
            transactionId: transaction.transactionId,
            transactionsNotes: transaction.transactionsNotes,
            valueDateTime: transaction.valueDateTime
        };
    }
}
