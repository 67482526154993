import { Component } from '@angular/core';
import {RouterLink, RouterOutlet} from '@angular/router';
import { HeaderComponent } from '../../shared/components/header/header.component';
import { SidebarComponent } from '../../shared/components/sidebar/sidebar.component';

@Component({
	selector: 'app-auth-layout',
	standalone: true,
    imports: [RouterOutlet, HeaderComponent, SidebarComponent, RouterLink],
	templateUrl: './auth-layout.component.html',
	styleUrl: './auth-layout.component.css',
})
export class AuthLayoutComponent {
	user = { username: 'Kouassi Serge', picture: '' };
	sidebarVisible: boolean = false;

	toggleSidebar() {
		this.sidebarVisible = !this.sidebarVisible;
	}
}
