import {NgModule} from "@angular/core";
import {ITransferRepository} from "./domain/repositories/transfer.repository";
import {TransferRepositoryImpl} from "./infrastructure/repositories/transfer.repository.impl";

@NgModule({
    providers:[
        { provide: ITransferRepository, useClass: TransferRepositoryImpl },
    ]
})
export class CoreTransferManagementModules {}
