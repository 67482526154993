import {Actions, createEffect, ofType} from "@ngrx/effects";
import {privacypolicyActions} from "../actions/privacy-policy.actions";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {Injectable} from "@angular/core";
import { GetPrivacyPolicyUseCase } from "../../application/use-cases/get-privacy-policy.use-case";


@Injectable()
export class PrivacyPolicyEffects {

    loadPrivacyPolicies = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(privacypolicyActions.loadPrivacyPolicies),
                switchMap(() => {
                    return this.getPrivacyPolicyUseCase.execute().pipe(
                        map((response) => privacypolicyActions.loadPrivacyPolicySuccess({policies: response})),
                        catchError((error) => {
                            return of(privacypolicyActions.loadPrivacyPolicyFailure({error}));
                        })
                    );
                })
            );
        }
    );
    constructor(
        private actions$: Actions,
        private getPrivacyPolicyUseCase: GetPrivacyPolicyUseCase
    ) {}
}