export class Account {
    constructor(
        public accountID: string,
        public Account_id: string,
        public productId: string,
        public accountType: string,
        public arrangementId: string,
        public displayName: string,
        public availableBalance: number,
        public currencyCode: string,
        public currentBalance: number,
        public openingDate: string,
        public principalBalance: number,
        public account_id: string,
        public nickName: string,
        public actions: string,
        public IBAN :  string,
        public categoryId : string,
        public Membership_id: string,
        public coreCustomerId: string,
        public MembershipName: string,
        public coreCustomerName: string,
        public isBusinessAccount: boolean,
        public favouriteStatus: string,
        public isSweepCreated: boolean,
        public accountStatus: string,
        public eStatementEnable: string
    ) {}
}