import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { IInfoRepository } from '../../domain/repositories/info.repository';
import { Agence } from '../../domain/entities/agence.entity';

@Injectable({
	providedIn: 'root',
})
export class GetAgenceUseCase {
	constructor(
		@Inject('IInfoRepository')
		private infoRepository: IInfoRepository
	) {}

	execute(): Observable<Agence[]> {
		return this.infoRepository.getAgence();
	}
}
