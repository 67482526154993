import { Observable } from 'rxjs';
import {
	LoanTransactionResponseDTO,
	TransactionRequestDTO,
	TransactionResponseDTO,
} from '../../application/dtos/transaction/transaction.dto';

export abstract class ITransactionService {
	abstract getUserScheduledTransactions(): Observable<TransactionResponseDTO>;
	abstract getRecentTransactions(
		request: TransactionRequestDTO
	): Observable<TransactionResponseDTO>;
	abstract getLoanTransactions(
		request: TransactionRequestDTO
	): Observable<LoanTransactionResponseDTO>;
}
