import { PartnerService } from "../../domain/entities/partner-service.entity";
import { ThirdPartyServiceDTO } from "../dtos/thridPartyService.dto";

export class PartnerServiceMapper {
    static toDTO(partnerService: PartnerService): ThirdPartyServiceDTO {
        return {
            entityIdService: partnerService.id,
            entityNameService: partnerService.name, 
        };
    }

    static toDomain(dto: ThirdPartyServiceDTO): PartnerService {
        return new PartnerService(
            dto.entityIdService,
            dto.entityNameService
        );
    }
}
