import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as MessageActions from '../actions/message.actions';
import { GetAllMessagesByDiscussionIdUseCase } from '../../application/use-cases/get-all-messages-by-discussion-id.use-case';

@Injectable()
export class MessageEffects {

    loadMessagesByDiscussionId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessageActions.loadMessages),
            mergeMap(({discussionId}) =>
                this.getMessagesByDiscussionIdUseCase.execute(discussionId).pipe(
                    map(messages => MessageActions.loadMessagesSuccess({ messages })),
                    catchError(error => of(MessageActions.loadMessagesFailure({ error })))
                ),
            )
        )
    );


    constructor(
        private actions$: Actions,
        private getMessagesByDiscussionIdUseCase: GetAllMessagesByDiscussionIdUseCase
    ) {}
}
