import {Observable, of} from "rxjs";
import { Transfer } from "../../domain/entities/transfer.entity";
import {ITransferRepository} from "../../domain/repositories/transfer.repository";

export class TransferRepositoryImpl implements ITransferRepository {
    recurringTransfer(): Observable<Transfer> {
        throw new Error("Method not implemented.");
    }

    transferToAnotherAccount(): Observable<Transfer> {
        throw new Error("Method not implemented.");
    }

    transferToExternalBank(): Observable<Transfer> {
        throw new Error("Method not implemented.");
    }

    transferToInternational(): Observable<Transfer> {
        throw new Error("Method not implemented.");
    }

    transferToPersonalAccount(): Observable<Transfer> {
        throw new Error("Method not implemented.");
    }

    validateTransferByOTP(code: string): Observable<boolean> {
        return of(true)
    }

    getTransferHistory(): Observable<Transfer[]> {
        return of([])
    }

}