import { createReducer, on } from '@ngrx/store';
import * as NotificationActions from '../actions/notification.actions';
import { NotificationCount } from '../../domain/entities/notification.entity';

export interface NotificationState {
	notifications: NotificationCount;
	loading: boolean;
	error: any;
}

export const initialState: NotificationState = {
	notifications: { unreadNotificationCount: '' },
	loading: false,
	error: null,
};

export const notificationReducer = createReducer(
	initialState,
	on(NotificationActions.loadNotifications, (state) => ({
		...state,
		loading: true,
	})),
	on(
		NotificationActions.loadNotificationsSuccess,
		(state, { notifications }) => ({
			...state,
			notifications,
			loading: false,
			error: null,
		})
	),
	on(NotificationActions.loadNotificationsFailure, (state, { error }) => ({
		...state,
		error,
		loading: false,
	}))
);
