import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {IBeneficiaryRepository} from '../../domain/repositories/beneficiary.repository';
import {Beneficiary} from '../../domain/entities/beneficiary.entity';
import {BeneficiaryMapper} from '../../application/mappers/beneficiary.mapper';
import {BeneficiaryService} from "../../../core-banking/application/services/beneficiary/beneficiary.service";
import {BeneficiaryResponseDTO, BeneficiaryUpSertRequestDTO} from "../../application/dtos/beneficiary.dto";
import {AuthTokenService} from "../../../authentication/application/services/auth-token.service";
import { Logger } from '../../../../shared/helpers/logger-helper';

@Injectable({
    providedIn: 'root'
})
export class BeneficiaryRepositoryImpl implements IBeneficiaryRepository {
    constructor(
        private beneficiaryService: BeneficiaryService,
        private authTokenService: AuthTokenService
    ) {}

    create(request: BeneficiaryUpSertRequestDTO): Observable<BeneficiaryResponseDTO> {
        let data = {...request, authToken: this.authTokenService.getToken() || '' };
        Logger.info('BeneficiaryRepositoryImpl request', request);
        return this.beneficiaryService.createBeneficiary(data).pipe(
            map(response => BeneficiaryMapper.upSertResponseDTO(response))
        );
    }

    getAll(): Observable<Beneficiary[]> {
        return this.beneficiaryService.getBeneficiaries().pipe(
            map(response => response.ExternalAccounts.map(dto => BeneficiaryMapper.toDomain(dto)))
        );
    }

    update(beneficiary: BeneficiaryUpSertRequestDTO): Observable<BeneficiaryResponseDTO> {
        return this.beneficiaryService.editBeneficiary(beneficiary).pipe(
            map(response => BeneficiaryMapper.upSertResponseDTO(response))
        );
    }

    delete(beneficiary: Beneficiary): Observable<BeneficiaryResponseDTO> {
        const deleteRequest = {
            payeeId: beneficiary.id,
            accountNumber: beneficiary.accountNumber,
            isSameBankAccount: beneficiary.isSameBankAccount.toString(),
            isInternationalAccount: beneficiary.isInternationalAccount.toString()
        };
        Logger.info('BeneficiaryRepositoryImpl delete beneficiary:', deleteRequest);
        return this.beneficiaryService.deleteBeneficiary(deleteRequest).pipe(
            map((response)  => BeneficiaryMapper.upSertResponseDTO(response))
        );

    }

    getPayeeName(accountNumber: string): Observable<string> {
        return this.beneficiaryService.getPayeeName({accountNumber}).pipe(
            map(response => response.beneficiaryName)
        );
    }
}
