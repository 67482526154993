import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CreateBeneficiaryUseCase } from './application/use-cases/create-beneficiary.use-case';
import { UpdateBeneficiaryUseCase } from './application/use-cases/update-beneficiary.use-case';
import { DeleteBeneficiaryUseCase } from './application/use-cases/delete-beneficiary.use-case';
import { GetPayeeNameUseCase } from './application/use-cases/get-payee-name.use-case';
import { BeneficiaryRepositoryImpl } from "./infrastructure/repositories/beneficiary.repository.impl";
import { GetBeneficiariesUseCase } from "./application/use-cases/get-beneficiaries.use-case";

@NgModule({
    imports: [
        CommonModule,
    ],
    providers: [
        { provide: 'IBeneficiaryRepository', useClass: BeneficiaryRepositoryImpl },
        GetBeneficiariesUseCase,
        CreateBeneficiaryUseCase,
        UpdateBeneficiaryUseCase,
        DeleteBeneficiaryUseCase,
        GetPayeeNameUseCase
    ]
})
export class CoreBeneficiaryModule { }
