import { createReducer, on } from '@ngrx/store';
import * as InfoActions from '../actions/info.actions';
import { ContactUs } from '../../domain/entities/contact-us.entity';
import { Agence } from '../../domain/entities/agence.entity';

export interface InfoState {
	contactUs: ContactUs;
	agences: Agence[];
	loading: boolean;
	error: any;
}

export const initialState: InfoState = {
	contactUs: {
		records: [],
		opstatus: '',
		httpStatusCode: '',
	},
	agences: [],
	loading: false,
	error: null,
};

export const infoReducer = createReducer(
	initialState,
	on(InfoActions.loadContactUs, InfoActions.loadAgence, (state) => ({
		...state,
		loading: true,
	})),
	on(InfoActions.loadContactUsSuccess, (state, { contactUs }) => {
		return {
			...state,
			contactUs,
			loading: false,
			error: null,
		};
	}),
	on(
		InfoActions.loadContactUsFailure,
		InfoActions.loadAgenceFailure,
		(state, { error }) => ({
			...state,
			error,
			loading: false,
		})
	),
	on(InfoActions.loadAgenceSuccess, (state, { agences }) => {
		return {
			...state,
			agences,
			loading: false,
			error: null,
		};
	})
);
