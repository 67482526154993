import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import * as BillActions from './core/bill-management/state/actions/bill.actions'
import { Store } from '@ngrx/store';
import { Partner } from './core/bill-management/domain/entities/partner.entity';
import { Observable } from 'rxjs';
import { selectAllPartners, selectAllPartnerServiceFields, selectAllPartnerServices } from './core/bill-management/state/selectors/bill.reducers';
import { PartnerService } from './core/bill-management/domain/entities/partner-service.entity';
import { PartnerServiceField } from './core/bill-management/domain/entities/partner-service-field.entity';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { Logger } from './shared/helpers/logger-helper';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  title = 'mybridge-touch-web';
  partners: Partner[] = []; 
  partners$: Observable<Partner[]>;
  partnerServices: PartnerService[] = []; 
  partnerServices$: Observable<PartnerService[]>;
  partnerServiceFields: PartnerServiceField[] = []; 
  partnerServiceFields$: Observable<PartnerServiceField[]>;

  constructor(
    private translate: TranslateService, 
    private store: Store
  ) {
    this.partners$ = this.store.select(selectAllPartners)
    this.partnerServices$ = this.store.select(selectAllPartnerServices)
    this.partnerServiceFields$ = this.store.select(selectAllPartnerServiceFields)

    this.setDefaultLanguage();
  }

  ngOnInit() {
    this.partners$.subscribe(partners =>{
      this.partners = partners
      Logger.info("PARTNERS ", this.partners)
    })
    this.getPartners()
    
    this.partnerServices$.subscribe(partnerServices => {
      this.partnerServices = partnerServices;
      Logger.info("PATNER SERVICES : ", this.partnerServices)
    })
    this.getPartnerServices()

    this.partnerServiceFields$.subscribe(partnerServiceFields => {
      this.partnerServiceFields = partnerServiceFields;
      Logger.info("INITIATE PARTNER SERVICES : ", this.partnerServiceFields)
    })
    this.initiatePartnerService()
  } 

  getPartners() {
    this.store.dispatch(BillActions.loadPartners())
  }

  getPartnerServices() {
    this.store.dispatch(BillActions.loadServicesByPartnerId({partnerId: 3}))
  }

  initiatePartnerService() {
    this.store.dispatch(BillActions.initiatePartnerService({
      serviceId: 4, 
      stepNum: 2, 
      payload: {
        "dataRequest": [
            {
                "keyJson": "operator",
                "valueJson": "ORANGE"
            },
            {
                "keyJson": "msisdn",
                "valueJson": "0707766902"
            },
            {
                "keyJson": "amount",
                "valueJson": "5000"
            }
        ]
      }
    }))
  } 

  setDefaultLanguage() {
      // Vérifie s'il existe une langue stockée dans localStorage
      const storedLang = localStorage.getItem('selectedLanguage');
      const browserLang = this.translate.getBrowserLang() || '';
      const supportedLangs = ['en', 'fr'];

      // Utilise la langue stockée ou la langue du navigateur si elle est supportée, sinon utiliser la language de l'environnement
      const defaultLang = storedLang || (supportedLangs.includes(browserLang) ? browserLang : environment.defaultLang);

      this.translate.setDefaultLang(defaultLang);
  }
}
