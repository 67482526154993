import {AccountDTO} from "../../../core-banking/application/dtos/account/account.dto";
import {Account} from "../../domain/entities/account.entity";
import {AccountManagementDto} from "../dtos/account-management.dto";

export class AccountMapper {
    static mapToDTO(accountManagement: Account): AccountManagementDto {
        return {
            accountID: accountManagement.accountID,
            Account_id: accountManagement.Account_id,
            productId: accountManagement.productId,
            accountType: accountManagement.accountType,
            arrangementId: accountManagement.arrangementId,
            displayName: accountManagement.displayName,
            availableBalance: accountManagement.availableBalance,
            currencyCode: accountManagement.currencyCode,
            currentBalance: accountManagement.currentBalance,
            openingDate: accountManagement.openingDate,
            principalBalance: accountManagement.principalBalance,
            account_id: accountManagement.account_id,
            nickName: accountManagement.nickName,
            actions: accountManagement.actions,
            IBAN: accountManagement.IBAN,
            categoryId: accountManagement.categoryId,
            Membership_id: accountManagement.Membership_id,
            coreCustomerId: accountManagement.coreCustomerId,
            MembershipName: accountManagement.MembershipName,
            coreCustomerName: accountManagement.coreCustomerName,
            isBusinessAccount: accountManagement.isBusinessAccount,
            favouriteStatus: accountManagement.favouriteStatus,
            isSweepCreated: accountManagement.isSweepCreated,
            accountStatus: accountManagement.accountStatus,
            eStatementEnable: accountManagement.eStatementEnable
        }
    }

    static mapToDomain(accountDTO: AccountManagementDto): Account {
        return new Account(
            accountDTO.accountID,
            accountDTO.Account_id,
            accountDTO.productId,
            accountDTO.accountType,
            accountDTO.arrangementId,
            accountDTO.displayName,
            accountDTO.availableBalance,
            accountDTO.currencyCode,
            accountDTO.currentBalance,
            accountDTO.openingDate,
            accountDTO.principalBalance,
            accountDTO.account_id,
            accountDTO.nickName,
            accountDTO.actions,
            accountDTO.IBAN ,
            accountDTO.categoryId,
            accountDTO.Membership_id,
            accountDTO.coreCustomerId,
            accountDTO.MembershipName,
            accountDTO.coreCustomerName,
            accountDTO.isBusinessAccount,
            accountDTO.favouriteStatus,
            accountDTO.isSweepCreated,
            accountDTO.accountStatus,
            accountDTO.eStatementEnable
        );
    }
}