import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { CoreBankingError } from '../../domain/errors/core-banking-error';

export interface CoreBankingErrorResponse {
    dbpErrCode: string;
    opstatus: number;
    dbpErrMsg: string;
    httpStatusCode: number;
}

export interface LoginErrorResponse {
    details: {
        message: string;
        errcode: number;
        errmsg: string;
    };
    httpstatus: string;
    requestid: string;
    domain: string;
    code: number;
    mfcode: string;
    message: string;
}

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlingService {
    handleError(error: any, code: string, message: string): Observable<never> {
        let coreBankingError: CoreBankingError;
        console.log("Error: ", error);
        if (this.isLoginErrorResponse(error)) {
            coreBankingError = new CoreBankingError(
                error.mfcode,
                error.details.errmsg,
                error.code,
                this.getHttpStatusCode(error.httpstatus),
                error
            );
        } else if (this.isCoreBankingErrorResponse(error)) {
            coreBankingError = new CoreBankingError(
                error.dbpErrCode,
                error.dbpErrMsg,
                error.opstatus,
                error.httpStatusCode,
                error
            );
        } else {
            coreBankingError = new CoreBankingError(
                code,
                message,
                0,
                500,
                error
            );
        }

        return throwError(() => coreBankingError);
    }

    private isCoreBankingErrorResponse(error: any): error is CoreBankingErrorResponse {
        return (
            error &&
            typeof error.dbpErrCode === 'string' &&
            typeof error.opstatus === 'number' &&
            typeof error.dbpErrMsg === 'string' &&
            typeof error.httpStatusCode === 'number'
        );
    }

    private isLoginErrorResponse(error: any): error is LoginErrorResponse {
        return (
            error &&
            error.details &&
            typeof error.details.errmsg === 'string' &&
            typeof error.mfcode === 'string' &&
            typeof error.code === 'number' &&
            typeof error.httpstatus === 'string'
        );
    }

    private getHttpStatusCode(httpstatus: string): number {
        switch (httpstatus.toLowerCase()) {
            case 'unauthorized':
                return 401;
            case 'forbidden':
                return 403;
            case 'not found':
                return 404;
            default:
                return 500;
        }
    }
}
