import {Account} from "../../domain/entities/account.entity";
import {IAccountRepository} from "../../domain/repositories/account.repository";
import {Observable} from "rxjs";
import {inject} from "@angular/core";
import {AccountService} from "../../../core-banking/application/services/account/account.service";
import {map} from "rxjs/operators";
import {AccountMapper} from "../../application/mappers/account.mapper";

export class AccountRepositoryImpl implements IAccountRepository {
    private accountService = inject(AccountService)

    getAccounts(): Observable<Account[]> {
        return this.accountService.getAccounts().pipe(
            map(response => {
                return response.Accounts.map(account => AccountMapper.mapToDomain(account))
            })
        );
    }
}