import {inject, Injectable} from "@angular/core";
import {ITransactionRepository} from "../../domain/repositories/transaction.repository";
import {Observable} from "rxjs";
import {Transaction} from "../../domain/entities/transaction.entity";

@Injectable({ providedIn: 'root'})
export class GetUserScheduledTransactionUseCase {
    transactionService = inject(ITransactionRepository)

    execute(): Observable<Transaction[]> {
        return this.transactionService.getUserScheduledTransactions();
    }
}
