export class DiscussionCategory {
   constructor(
         public id: string,
         public lastmodifiedts: string,
         public createdby: string,
         public modifiedby: string,
         public synctimestamp: string,
         public createdts: string,
         public softdeleteflag: string,
         public Name: string,
   ) {}
}
  