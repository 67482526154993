import {Inject, Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { IAuthRepository } from '../../domain/repositories/auth.repository';

@Injectable({
    providedIn: 'root'
})
export class LogoutUseCase {
    constructor(@Inject('IAuthRepository') private authRepository: IAuthRepository) {}

    execute(): Observable<void> {
        return this.authRepository.logout();
    }
}
