import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { CoreBankingApiService } from "../../../infrastructure/api/core-banking-api.service";
import { ErrorHandlingService } from "../../../infrastructure/services/error-handling.service";
import { IDiscussionService } from "../../../domain/interfaces/discussion-service.interface";
import {
  DiscussionResponseDTO,
  UpdateDiscussionRequestDTO,
  UnreadDiscussionCountDTO,
  GetDiscussionsResponseDTO,
  DiscussionDTO,
} from "../../dtos/discussion/discussion.dto";
import {
  CoreBankingError,
  InvalidParametersException,
  UnauthorizedException,
} from "../../../domain/errors/core-banking-error";
import { CORE_BANKING_API_ENDPOINTS } from "../../../infrastructure/constants/api-endpoints";
import { DiscussionCategoryListResponseDTO } from "../../dtos/discussion/discussion-category";

@Injectable({
  providedIn: "root",
})
export class DiscussionService implements IDiscussionService {
  constructor(
    private api: CoreBankingApiService,
    private errorHandler: ErrorHandlingService
  ) {}


  createDiscussion(
    request: DiscussionDTO
  ): Observable<DiscussionResponseDTO> {
    return this.api
      .post<DiscussionResponseDTO>(
        CORE_BANKING_API_ENDPOINTS.MESSAGES.CREATE_MESSAGE,
        request
      )
      .pipe(
        map((response) => {
          if (response.dbpErrCode) {
            if (response.validationError) {
              throw new InvalidParametersException(response.validationError);
            } else {
              this.handleSpecificErrors(
                response.dbpErrCode,
                response.dbpErrMsg || "",
                response
              );
            }
          } else {
            return response;
          }
        }),
        catchError((error) => {
          if (error instanceof CoreBankingError) {
            return throwError(() => error);
          } else if (error instanceof InvalidParametersException) {
            return throwError(() => error);
          }
          return this.errorHandler.handleError(
            error,
            "CREATE_MESSAGE_FAILED",
            "Failed to create discussion"
          );
        })
      );
  }

  updateDiscussion(
    request: UpdateDiscussionRequestDTO
  ): Observable<DiscussionResponseDTO> {
    return this.api
      .post<DiscussionResponseDTO>(
        CORE_BANKING_API_ENDPOINTS.MESSAGES.UPDATE_MESSAGE,
        request
      )
      .pipe(
        map((response) => {
          if (response.dbpErrCode) {
            if (response.validationError) {
              throw new InvalidParametersException(response.validationError);
            } else {
              this.handleSpecificErrors(
                response.dbpErrCode,
                response.dbpErrMsg || "",
                response
              );
            }
          } else {
            return response;
          }
        }),
        catchError((error) => {
          if (error instanceof CoreBankingError) {
            return throwError(() => error);
          } else if (error instanceof InvalidParametersException) {
            return throwError(() => error);
          }
          return this.errorHandler.handleError(
            error,
            "UPDATE_MESSAGE_FAILED",
            "Failed to update discussion"
          )
        }
        )
      );
  }

  getDiscussions(): Observable<GetDiscussionsResponseDTO> {
    return this.api
      .post<GetDiscussionsResponseDTO>(
        CORE_BANKING_API_ENDPOINTS.MESSAGES.GET_MESSAGES,
        {}
      )
      .pipe(
        map((response) => {
          console.log("CoreBanking API getDiscussions: ",  response.customerrequests_view);
          return response;
        }),
        catchError((error) => {
          if (error instanceof UnauthorizedException) {
            return throwError(() => error);
          }
          return this.errorHandler.handleError(
            error,
            "GET_MESSAGES_FAILED",
            "Failed to get discussions"
          );
        })
      );
  }

  getUnreadDiscussionCount(): Observable<UnreadDiscussionCountDTO> {
    return this.api
      .post<UnreadDiscussionCountDTO>(
        CORE_BANKING_API_ENDPOINTS.MESSAGES.GET_UNREAD_COUNT,
        {}
      )
      .pipe(
        catchError((error) =>
          this.errorHandler.handleError(
            error,
            "GET_UNREAD_MESSAGE_COUNT_FAILED",
            "Failed to get unread discussion count"
          )
        )
      );
  }

  getDiscussionCategories(): Observable<DiscussionCategoryListResponseDTO> {
    return this.api
      .post<DiscussionCategoryListResponseDTO>(
        CORE_BANKING_API_ENDPOINTS.MESSAGES.GET_CATEGORIES,
        {}
      )
      .pipe(
        catchError((error) =>
          this.errorHandler.handleError(
            error,
            "GET_MESSAGE_CATEGORIES_FAILED",
            "Failed to get discussion categories"
          )
        )
      );
  }

  private handleSpecificErrors(
    errorCode: string,
    errorDiscussion: string,
    response: any
  ): never {
    switch (errorCode.toString()) {
      case "20001":
        throw new CoreBankingError(errorCode, errorDiscussion, 0, 400, response);
      case "20541":
        throw new CoreBankingError(errorCode, errorDiscussion, 0, 400, response);
      case "21027":
        throw new CoreBankingError(errorCode, errorDiscussion, 0, 400, response);
      case "20131":
        throw new CoreBankingError(errorCode, errorDiscussion, 0, 400, response);
      default:
        throw new Error(errorDiscussion);
    }
  }
}
