import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { IInfoRepository } from '../../domain/repositories/info.repository';
import { ContactUs } from '../../domain/entities/contact-us.entity';

@Injectable({
	providedIn: 'root',
})
export class GetContactUSUseCase {
	constructor(
		@Inject('IInfoRepository')
		private infoRepository: IInfoRepository
	) {}

	execute(): Observable<ContactUs> {
		return this.infoRepository.getContactUs();
	}
}
