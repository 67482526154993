import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiscussionRepositoryImpl } from './infrastructure/repositories/discussion.repository.impl';
import { MessageRepositoryImpl } from './infrastructure/repositories/message.repository.impl';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
      { provide: 'IDiscussionRepository', useClass: DiscussionRepositoryImpl },
      { provide: 'IMessageRepository', useClass: MessageRepositoryImpl },
  ]
  })
export class CoreDiscussionModule { }
