import { FaqCategory } from "../../domain/entities/faqs.entity";
import { FaqCategoriesPDO } from "../dto/faqs.pdo";

export class FaqsMapper {
    static toDTO(faqCategory: FaqCategory): FaqCategoriesPDO {
        return {
            faqs: faqCategory.faqs,
            categoryName: faqCategory.categoryName,
            categoryId: faqCategory.categoryId
        };
    }

    static toDomain(dto: FaqCategoriesPDO): FaqCategory {
        return new FaqCategory(
            dto.faqs,
            dto.categoryName,
            dto.categoryId
        );
    }
}
