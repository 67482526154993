export class UserContract {
    constructor(
        public contractId: string,
        public contractName: string,
        public contractCustomers: ContractCustomer[]
    ) {}
}

export class ContractCustomer {
    constructor(
        public coreCustomerId: string,
        public isPrimary: boolean,
        public isBusiness: boolean,
        public coreCustomerName: string,
        public userRole: string,
        public actions: string[]
    ) {}
}
