import { Partner } from "../../domain/entities/partner.entity";
import { ThirdPartyDTO } from "../dtos/thirdParty.dto";

export class PartnerMapper {
    static toDTO(partner: Partner): ThirdPartyDTO {
        return {
            entityId: partner.id,
            entityName: partner.name,
            entityLogo: partner.logo    
        };
    }

    static toDomain(dto: ThirdPartyDTO): Partner {
        return new Partner(
            dto.entityId,
            dto.entityName,
            dto.entityLogo
        );
    }
}
