import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthTokenService } from '../../core/authentication/application/services/auth-token.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private tokenService: AuthTokenService, private router: Router) {}

    canActivate(): boolean {
        const isLogged = this.tokenService.isLogged();

        if (!isLogged) {
            console.log('Token is null, redirecting to login');
            this.tokenService.removeTokens();
            this.router.navigate(['/auth/login']);
            return false;
        }
        return true;
    }
}
