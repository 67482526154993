import { Observable } from 'rxjs';
import { Inject, inject, Injectable } from '@angular/core';
import { IPrivacyPolicyRepository } from '../../domain/repositories/privacy-policy.repository';
import { PrivacyPolicy } from '../../domain/entities/privacy-policy.entity';

@Injectable({
	providedIn: 'root',
})
export class GetPrivacyPolicyUseCase {
	//private privacyPolicyRepository = inject(IPrivacyPolicyRepository);
	constructor(
		@Inject('IPrivacyPolicyRepository')
		private privacyPolicyRepository: IPrivacyPolicyRepository
	) {}
	execute(): Observable<PrivacyPolicy[]> {
		return this.privacyPolicyRepository.getPrivacyPolicies();
	}
}
