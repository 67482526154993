import { Injectable } from "@angular/core";
import { IMessageRepository } from "../../domain/repositories/message.repository";
import { catchError, map, Observable, throwError } from "rxjs";
import { Message } from "../../domain/entities/message.entity";
import { MessageService } from "../../../core-banking/application/services/discussion/message.service";
import { GetMessagesResponseDTO } from "../../../core-banking/application/dtos/discussion/message.dto";
import { MessageMapper } from "../../application/mappers/message.mapper";
import { CoreBankingError } from "../../../core-banking/domain/errors/core-banking-error";
import { adaptCoreBankingError } from "../adapters/error-adapter";
import { MessageError } from "../../domain/entities/error.entity";

@Injectable({
    providedIn: "root",
  })
  export class MessageRepositoryImpl implements IMessageRepository {
    constructor(private messageService: MessageService) {}

    getMessagesByDiscussionId(discussionId: string): Observable<Message[]> {
        return this.messageService.getMessagesByDiscussionId(discussionId).pipe(
            map((response) => {
                return response.messages.map((dto) =>
                    MessageMapper.toDomain(dto)
                )
            }),
            catchError((error: unknown) => {
              if (error instanceof CoreBankingError) {
                const adaptedError = adaptCoreBankingError(error);
                return throwError(() => MessageError.fromError(adaptedError));
              }
              return throwError(() => MessageError.UNKNOWN_ERROR);
            })
        );
    }
}