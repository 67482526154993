import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IAccountRepository} from "./domain/repositories/account.repository";
import {AccountRepositoryImpl} from "./infrasructure/repositories/account.repository.impl";
import {provideEffects} from "@ngrx/effects";
import {AccountEffects} from "./state/account.effects";

@NgModule({
    imports: [
        CommonModule,
    ],
    providers: [
        { provide: IAccountRepository, useClass: AccountRepositoryImpl },
        provideEffects(AccountEffects)
    ]
})
export class CoreAccountManagementModule { }
