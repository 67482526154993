import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as FaqAction from "../actions/faq.actions";
import { catchError, map, of, switchMap } from "rxjs";
import { getFaqsUseCase } from "../../application/use-cases/get-faqs.use-case";

@Injectable()
export class FaqEffects {

    loadfaqs$ = createEffect(() =>{
        return this.actions$.pipe(
            ofType(FaqAction.loadFaqs),
            switchMap(() => {
                return this.getFaqsUseCase.execute().pipe(
                    map(response => FaqAction.loadFaqsSuccess({ categories: response })),
                    catchError(error =>{ 
                        return of( FaqAction.loadFaqsFailure({ error: error }))
                    })
                );
            })
        )
    })

    // () => {
    //     return this.actions$.pipe(
    //         ofType(privacypolicyActions.loadPrivacyPolicies),
    //         switchMap(() => {
    //             return this.getPrivacyPolicyUseCase.execute().pipe(
    //                 map((response) => privacypolicyActions.loadPrivacyPolicySuccess({policies: response})),
    //                 catchError((error) => {
    //                     return of(privacypolicyActions.loadPrivacyPolicyFailure({error}));
    //                 })
    //             );
    //         })
    //     );
    // }

    constructor(
        private actions$: Actions,
        private getFaqsUseCase : getFaqsUseCase
    ){}
}