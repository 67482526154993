import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TransactionActions } from '../actions/transaction.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { GetRecentUserTransactionUseCase } from '../../application/use-cases/get-recent-user-transaction.use-case';
import { GetUserScheduledTransactionUseCase } from '../../application/use-cases/get-user-scheduled-transaction.use-case';
import { GetLoanAccountTransactionUseCase } from '../../application/use-cases/get-loan-account-transaction.use-case';

@Injectable()
export class TransactionEffects {
	loadScheduledTransactions$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(TransactionActions.loadScheduledTransactions),
			switchMap(() =>
				this.getUserScheduledTransaction.execute().pipe(
					map((transactions) =>
						TransactionActions.loadTransactionsSuccess({ transactions })
					),
					catchError((error) =>
						of(TransactionActions.loadTransactionsFailure({ error }))
					)
				)
			)
		);
	});

	loadRecentTransactions$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(TransactionActions.loadRecentTransactions),
			switchMap(({ request }) => {
				return this.getRecentUserTransactions.execute(request).pipe(
					map((transactions) =>
						TransactionActions.loadTransactionsSuccess({ transactions })
					),
					catchError((error) =>
						of(TransactionActions.loadTransactionsFailure({ error }))
					)
				);
			})
		);
	});

	loadLoanTransactions$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(TransactionActions.loadLoanTransactions),
			switchMap(({ request }) => {
				return this.getLoanAccountTransactionUseCase.execute(request).pipe(
					map((transactions) =>
						TransactionActions.loadLoanTransactionsSuccess({
							transactions,
						})
					),
					catchError((error) =>
						of(TransactionActions.loadTransactionsFailure({ error }))
					)
				);
			})
		);
	});

	selectTransactionById$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(TransactionActions.selectTransactionById),
			switchMap(({ id }) =>
				of().pipe(
					map((transaction) =>
						TransactionActions.selectTransactionByIdSuccess({
							transaction,
						})
					),
					catchError((error) =>
						of(TransactionActions.loadTransactionsFailure({ error }))
					)
				)
			)
		);
	});

	constructor(
		private readonly actions$: Actions,
		private readonly getRecentUserTransactions: GetRecentUserTransactionUseCase,
		private readonly getUserScheduledTransaction: GetUserScheduledTransactionUseCase,
		private readonly getLoanAccountTransactionUseCase: GetLoanAccountTransactionUseCase
	) {}
}
