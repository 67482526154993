import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { INotificationRepository } from '../../domain/repositories/notification.repository';
import { NotificationCount } from '../../domain/entities/notification.entity';

@Injectable({
	providedIn: 'root',
})
export class GetNotificationsUseCase {
	constructor(
		@Inject('INotificationRepository')
		private notificationRepository: INotificationRepository
	) {}

	execute(): Observable<NotificationCount> {
		return this.notificationRepository.getAll();
	}
}
