export const CORE_BANKING_API_ENDPOINTS = {
	AUTH: {
		LOGIN: '/login?provider=DbxUserLogin',
		FEATURE_PERMISSIONS: '/services/data/v1/Login/operations/Users/getFeaturesAndPermissions',
		LOGOUT: '/logout?provider=DbxUserLogin',
		REGISTER:
			'/services/data/v1/ExternalUserManagement/operations/ExternalUsers_1/bbgEnrollRetailUser',
		ACTIVATE_ACCOUNT:
			'/services/data/v1/ExternalUserManagement/operations/ExternalUsers_2/validateActivationCodeForEnrollment',
		CREATE_PASSWORD:
			'/services/data/v1/ExternalUserManagement/operations/ExternalUsers_2/UpdatePasswordForActivationFlow',
		GET_USER_CONTRACTS:
			'/services/data/v1/ExternalUserManagement/operations/ExternalUsers_1/getInfinityUserContractCustomers',
		REFRESH_TOKEN: '/refresh-token',
		VERIFY_CORE_USER:
			'/services/data/v1/Login/operations/Users_2/bbgVerifyCoreUser',
		REQUEST_RESET_PASSWORD_OTP:
			'/services/data/v1/Login/operations/Users_2/requestResetPasswordOTP',
		VERIFY_OTP:
			'/services/data/v1/Login/operations/Users_2/verifyOTPPreLogin',
		RESET_PASSWORD:
			'/services/data/v1/Login/operations/Users_2/resetUserPassword',
		RESEND_ACTIVATE_CODE:
			'/services/data/v1/ExternalUserManagement/operations/ExternalUsers_1/bbgResendActivationCode',
	},

	ACCOUNT: {
		GET_ACCOUNTS:
			'/services/data/v1/Holdings/operations/DigitalArrangements/bbgGetDashboardAccounts',
	},

	BENEFICIARY: {
		CREATE_BENEFICIARY:
			'/services/data/v1/PayeeManagement/operations/Payees/createPayee',
		EDIT_BENEFICIARY:
			'/services/data/v1/PayeeManagement/operations/Payees/editPayee',
		DELETE_BENEFICIARY:
			'/services/data/v1/PayeeManagement/operations/Payees/deletePayee',
		GET_BENEFICIARIES_LIST:
			'/services/data/v1/PayeeManagement/operations/Payees/getPayeesList',
		GET_BENEFICIARY_NAME:
			'/services/data/v1/PayeeManagement/operations/Payee_Name/getPayeeName',
	},

	LEGAL_ENTITIES: {
		GET_LEGAL_ENTITIES:
			'/services/data/v1/Utility/operations/LegalEntity/getLegalEntities',
		UPDATE_CURRENT_LEGAL_ENTITIES:
			'/services/data/v1/Utility/operations/LegalEntity/UpdateCurrentEntityInCache',
	},

	NOTIFICATIONS: {
		GET_NOTIFICATIONS:
			'/services/data/v1/Notifications/operations/UnreadNotifications/GetMessagesNotifications',
	},
	TRANSACTIONS: {
		GET_SCHEDULED_TRANSACTIONS:
			'/services/data/v1/Holdings/operations/TransactionsList/getUserScheduledTransactions',
		GET_RECENT_TRANSACTIONS:
			'/services/data/v1/Holdings/operations/TransactionsList/getRecent',
	},
	USER: {
		GET_CONTRACTS:
			'/services/data/v1/ExternalUserManagement/operations/ExternalUsers_1/getInfinityUserContractCustomers',
		VERIFY: '/services/data/v1/Login/operations/Users_2/bbgVerifyCoreUser',
		TERMS_AND_CONDITIONS:
			'/services/data/v1/ContentManagement/operations/TermsAndConditions/getPreLogin',
	},
	BENEFICIARIES: {
		BASE_URL: '/services/data/v1/PayeeManagement/operations/Payees',
		GET_ALL: '/getPayeesList',
		EDIT: '/editPayee',
		DELETE: '/deletePayee',
		GET_PAYEE_NAME:
			'/services/data/v1/PayeeManagement/operations/Payee_Name/getPayeeName',
	},
	INFO: {
		BASE_URL: '/services/data/v1/ContentManagement/operations',
		GET_CONTACT_US: '/Information/getContactUs',
		GET_AGENCE: '/everest/api/v1/agence',
	},
	MESSAGES: {
		CREATE_MESSAGE:
			'/services/data/v1/SecureMessaging/operations/Message/createRequest',
		GET_MESSAGES:
			'/services/data/v1/SecureMessaging/operations/Message/getRequests',
		GET_MESSAGES_BY_DISCUSSION_ID: "/services/data/v1/SecureMessaging/operations/Message/getAllMessagesForARequest",
		UPDATE_MESSAGE:
			'/services/data/v1/SecureMessaging/operations/Message/updateRequest',
		GET_UNREAD_COUNT:
			'/services/data/v1/SecureMessaging/operations/Message/getUnreadMessageCount',
		GET_CATEGORIES:
			'/services/data/v1/SecureMessaging/operations/Message/getRequestCategory',
	},
};
