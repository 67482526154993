<div
	class="min-h-screen flex flex-col lg:flex-row items-center justify-center bg-white p-6 lg:px-24"
>
	<!-- Section gauche : Formulaire de connexion et Bannière promotionnelle -->
	<div class="w-full max-w-xl lg:w-2/3">
		<!-- Logo de la banque centré -->
		<div class="flex justify-center mb-8">
			<a [routerLink]="['/']">
				<img
					src="assets/images/LOGO-BBGCI-NOIR.svg"
					alt="Bridge Bank Logo"
					class="h-16"
				/>
			</a>
		</div>
		<router-outlet></router-outlet>
	</div>
	<!-- Section droite : Illustration -->
	<div class="hidden lg:flex justify-center items-center lg:w-1/3 ms-20">
		<img
			src="assets/images/image-de-garde.svg"
			alt="MyBridge Touch"
			class="w-full max-w-lg"
		/>
	</div>
</div>
