import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Logger } from '../../../../shared/helpers/logger-helper';

@Injectable({
	providedIn: 'root',
})
export class CoreBankingApiService {
	private apiUrl = environment.apiUrl;
	private loginUrl = environment.loginUrl;
	private apiKey = environment.apiKey;
	private appSecret = environment.appSecret;
	private apiInfo = environment.apiInfo;

	constructor(private http: HttpClient) {
		Logger.info('CoreBankingApiService created');
		Logger.info('API URL:', this.apiUrl);
		Logger.info('Login URL:', this.loginUrl);
	}

	private getHeaders(
		customHeaders: { [key: string]: string } = {}
	): HttpHeaders {
		return new HttpHeaders({
			'Content-Type': 'application/json',
			'X-Kony-App-Key': this.apiKey,
			'X-Kony-App-Secret': this.appSecret,
			'X-Kony-ReportingParams': '{"aid":"OnlineBanking"}',
			// 'Access-Control-Allow-Origin': '*',
		});
	}

	private getBasicAuthHeaders(): HttpHeaders {
		const basicAuthToken = btoa(`${this.apiKey}:${this.appSecret}`);
		return new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Basic ${basicAuthToken}`,
			// 'Access-Control-Allow-Origin': '*',
		});
	}

	private getHeaderKonyAuthorization(token?: string): HttpHeaders {
		return new HttpHeaders({
			'Content-Type': 'application/json',
			'X-Kony-Authorization': `${token}`,
		});
	}

	getLegalEntities<T>(endpoint: string, data?: any): Observable<T> {
		Logger.info('GET request with Basic Authentication for Legal Entities');
		return this.http.post<T>(`${this.apiUrl}${endpoint}`, data, {
			headers: this.getBasicAuthHeaders(),
			// withCredentials: true,
		});
	}

	updateCurrentEntityInCache<T>(
		endpoint: string,
		data?: any,
		token?: string
	): Observable<T> {
		return this.http.post<T>(`${this.apiUrl}${endpoint}`, data, {
			headers: this.getHeaderKonyAuthorization(token),
		});
	}


	get<T>(endpoint: string, params?: any): Observable<T> {
		return this.http.get<T>(`${this.apiUrl}${endpoint}`, {
			headers: this.getHeaders(),
			params: new HttpParams({ fromObject: params }),
			// withCredentials: true,
		});
	}

	post<T>(
		endpoint: string,
		data?: any,
		includeToken: boolean = true
	): Observable<T> {
		return this.http.post<T>(`${this.apiUrl}${endpoint}`, data, {
			headers: this.getHeaders(),
			// withCredentials: true,
		});
	}

	postKony<T>(
		endpoint: string,
		data?: any,
		token?: string
	): Observable<T> {
		return this.http.post<T>(`${this.apiUrl}${endpoint}`, data, {
			headers: this.getHeaderKonyAuthorization(token),
		});
	}

	login<T>(endpoint: string, data: any): Observable<T> {
		return this.http.post<T>(`${this.loginUrl}${endpoint}`, data, {
			headers: this.getHeaders(),
			// withCredentials: true,
		});
	}

	register<T>(endpoint: string, data: any): Observable<T> {
		return this.http.post<T>(`${this.apiUrl}${endpoint}`, data, {
			headers: this.getBasicAuthHeaders(),
			// withCredentials: true,
		});
	}

	put<T>(endpoint: string, data: any): Observable<T> {
		return this.http.put<T>(`${this.apiUrl}${endpoint}`, data, {
			headers: this.getHeaders(),
			// withCredentials: true,
		});
	}

	delete<T>(endpoint: string): Observable<T> {
		return this.http.delete<T>(`${this.apiUrl}${endpoint}`, {
			headers: this.getHeaders(),
			// withCredentials: true,
		});
	}

	postVerifyCoreUser<T>(endpoint: string, data?: any): Observable<T> {
		return this.http.post<T>(`${this.apiUrl}${endpoint}`, data, {
			headers: this.getBasicAuthHeaders(),
			// withCredentials: true,
		});
	}

	requestResetPasswordOTP<T>(endpoint: string, data?: any): Observable<T> {
		return this.http.post<T>(`${this.apiUrl}${endpoint}`, data, {
			headers: this.getBasicAuthHeaders(),
			// withCredentials: true,
		});
	}

	verifyOTP<T>(endpoint: string, data?: any): Observable<T> {
		return this.http.post<T>(`${this.apiUrl}${endpoint}`, data, {
			headers: this.getBasicAuthHeaders(),
			// withCredentials: true,
		});
	}

	resetPassword<T>(endpoint: string, data?: any): Observable<T> {
		return this.http.post<T>(`${this.apiUrl}${endpoint}`, data, {
			headers: this.getBasicAuthHeaders(),
			// withCredentials: true,
		});
	}

	privacyPolicy<T>(endpoint: string, data?: any): Observable<T> {
		return this.http.post<T>(`${this.apiUrl}${endpoint}`, data, {
			headers: this.getBasicAuthHeaders(),
			// withCredentials: true,
		});
	}

	getContactUs<T>(
		endpoint: string,
		companyId: string,
		data?: any
	): Observable<T> {
		return this.http.post<T>(`${this.apiUrl}${endpoint}`, data, {
			headers: this.getHeaders({ companyId }),
			// withCredentials: true,
		});
	}

	getAgence<T>(
		endpoint: string,
		companyId: string,
		params?: any
	): Observable<T> {
		return this.http.get<T>(`${this.apiInfo}${endpoint}`, {
			headers: this.getHeaders({ companyId }),
			params: new HttpParams({ fromObject: params }),
			// withCredentials: true,
		});
	}

	resendActivateCode<T>(endpoint: string, data?: any): Observable<T> {
		return this.http.post<T>(`${this.apiUrl}${endpoint}`, data, {
			headers: this.getBasicAuthHeaders(),
			// withCredentials: true,
		});
	}
}
