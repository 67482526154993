import {
	ApplicationConfig,
	importProvidersFrom,
	inject,
	provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { routes } from './app.routes';
import {
	HTTP_INTERCEPTORS,
	HttpClient,
	provideHttpClient,
	withInterceptorsFromDi,
} from '@angular/common/http';
import { CoreBankingModule } from './core/core-banking/core-banking.module';
import { provideStore } from '@ngrx/store';
import { authReducer } from './core/authentication/state/reducers/auth.reducer';
import { beneficiaryReducer } from './core/beneficiary-management/state/reducers/beneficiary.reducer';
import { provideEffects } from '@ngrx/effects';
import { AuthEffects } from './core/authentication/state/effects/auth.effects';
import { CoreAuthenticationModule } from './core/authentication/CoreAuthentication.module';
import { localStorageSyncReducer } from './shared/reducers/meta-reducers';
import { AuthInterceptor } from './core/authentication/infrastructure/interceptors/auth.interceptor';
import { CoreBeneficiaryModule } from './core/beneficiary-management/CoreBeneficiary.module';
import { BeneficiaryEffects } from './core/beneficiary-management/state/effects/beneficiary.effects';
import { CoreTransactionManagementModule } from './core/transaction-management/CoreTransactionManagement.module';
import { CoreAccountManagementModule } from './core/account-management/CoreAccountManagement.module';
import { accountReducer } from './core/account-management/state/account.reducer';
import { transactionReducer } from './core/transaction-management/state/reducers/transaction.reducers';
import { AccountEffects } from './core/account-management/state/account.effects';
import { TransactionEffects } from './core/transaction-management/state/effects/transaction.effects';
import { CoreNotificationModule } from './core/notification/CoreNotification.module';
import { NotificationEffects } from './core/notification/state/effects/notification.effects';
import { notificationReducer } from './core/notification/state/reducers/notification.reducer';
import { CoreTransferManagementModules } from './core/transfer-management/CoreTransferManagement.modules';
import { privacyPolicyReducer } from './core/privacy-policy/state/reducers/privacy-policy.reducter';
import { PrivacyPolicyEffects } from './core/privacy-policy/state/effects/privacy-policy.effects';
import { CorePrivacyPolicyModule } from './core/privacy-policy/CorePrivacyPolicy.module';
import { CoreFaqModule } from './core/faqs/CoreFaq.module';
import { FaqEffects } from './core/faqs/state/effects/faq.effects';
import { faqReducer } from './core/faqs/state/reducers/faqs.reducers';
import { CoreInfoModule } from './core/info/CoreInfo.module';
import { InfoEffects } from './core/info/state/effects/info.effects';
import { infoReducer } from './core/info/state/reducers/info.reducer';
import { CoreDiscussionModule } from './core/message-management/core-message.module';
import { DiscussionEffects } from './core/message-management/state/effects/discussion.effects';
import { discussionReducer } from './core/message-management/state/reducers/discussion.reducers';
import { messageReducer } from './core/message-management/state/reducers/message.reducers';
import { MessageEffects } from './core/message-management/state/effects/message.effects';
import { billReducer } from './core/bill-management/state/reducers/bill.reducers';
import { BillEffects } from './core/bill-management/state/effects/bill.effects';
import { CoreBillModule } from './core/bill-management/core-bill.module';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}



export const appConfig: ApplicationConfig = {
	providers: [
		provideZoneChangeDetection({ eventCoalescing: true }),
		provideRouter(routes),
		provideAnimations(),
		provideHttpClient(withInterceptorsFromDi()),
		provideStore(
			{
				auth: authReducer,
				beneficiary: beneficiaryReducer,
				account: accountReducer,
				transaction: transactionReducer,
				notification: notificationReducer,
				discussion: discussionReducer,
				message: messageReducer,
				bill: billReducer,
				privacyPolicy : privacyPolicyReducer,
				faq : faqReducer,
				info: infoReducer,
			},
			{ metaReducers: [localStorageSyncReducer] }
		),
		provideEffects(
			AuthEffects,
			BeneficiaryEffects,
			AccountEffects,
			TransactionEffects,
			NotificationEffects,
			DiscussionEffects,
			MessageEffects,
			BillEffects,
			PrivacyPolicyEffects,
			FaqEffects,
			InfoEffects
		),
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		importProvidersFrom(
			TranslateModule.forRoot({
				loader: {
					provide: TranslateLoader,
					useFactory: HttpLoaderFactory,
					deps: [HttpClient],
				},
			}),
			CoreBankingModule,
			CoreAuthenticationModule,
			CoreAccountManagementModule,
			CoreBeneficiaryModule,
			CoreTransactionManagementModule,
			CoreNotificationModule,
			CoreTransferManagementModules,
			CoreDiscussionModule,
			CoreBillModule,
			CorePrivacyPolicyModule,
			CoreFaqModule,
			CoreInfoModule
		)
	],
};
