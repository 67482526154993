import { Injectable } from '@angular/core';
import { CoreBankingApiService } from '../../../infrastructure/api/core-banking-api.service';
import { Observable } from 'rxjs';
import { AccountResponseDTO } from '../../dtos/account/account.dto';
import { ErrorHandlingService } from '../../../infrastructure/services/error-handling.service';
import { catchError } from 'rxjs/operators';
import { CORE_BANKING_API_ENDPOINTS } from '../../../infrastructure/constants/api-endpoints';

@Injectable({
	providedIn: 'root',
})
export class AccountService {
	constructor(
		private api: CoreBankingApiService,
		private errorHandler: ErrorHandlingService
	) {}

	getAccounts(): Observable<AccountResponseDTO> {
		return this.api
			.post<AccountResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.ACCOUNT.GET_ACCOUNTS
			)
			.pipe(
				catchError((error) =>
					this.errorHandler.handleError(
						error,
						'GET_ACCOUNTS_FAILED',
						'Failed to get accounts'
					)
				)
			);
	}
}
