import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as BillActions from '../actions/bill.actions'
import { GetAllPartnersUseCase } from "../../application/use-cases/get-all-partners.use-case";
import { catchError, map, mergeMap, of, switchMap } from "rxjs";
import { GetServicesByPartnerIdUseCase } from "../../application/use-cases/get-services-by-partner-id.use-case";
import { InitiatePartnerServiceUseCase } from "../../application/use-cases/initiate-partner-service.use-case";

@Injectable()
export class BillEffects {
    loadPartners$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BillActions.loadPartners),
            switchMap(() =>
                this.getAllPartnersUseCase.execute().pipe(
                    map(partners => BillActions.loadPartnersSuccess({ partners })),
                    catchError(error => of(BillActions.loadPartnersFailure({ error })))
                )
            )
        )
    )

    loadServicesByPartnerId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BillActions.loadServicesByPartnerId),
            mergeMap(({partnerId}) =>
                this.getServicesByPartnerIdUseCase.execute(partnerId).pipe(
                    map(partnerServices => BillActions.loadServicesByPartnerIdSuccess({ partnerServices })),
                    catchError(error => of(BillActions.loadServicesByPartnerIdFailure({ error })))
                ),
            )
        )
    );

    initiatePartnerService$ = createEffect(() => 
        this.actions$.pipe(
            ofType(BillActions.initiatePartnerService),
            mergeMap(({serviceId, stepNum, payload}) => 
                this.initiatePartnerServiceUseCase.execute(serviceId, stepNum, payload).pipe(
                    map(partnerServiceFields => BillActions.initiatePartnerServiceSuccess({ partnerServiceFields })),
                    catchError(error => of(BillActions.initiatePartnerServiceFailure({error})))
                )
            )
        )
    )

    constructor(
        private actions$: Actions,
        private getAllPartnersUseCase: GetAllPartnersUseCase,
        private getServicesByPartnerIdUseCase: GetServicesByPartnerIdUseCase,
        private initiatePartnerServiceUseCase: InitiatePartnerServiceUseCase
    ) {}
}