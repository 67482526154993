import { createFeatureSelector, createSelector } from "@ngrx/store";
import { BillState } from "../reducers/bill.reducers";

export const selectBillState = createFeatureSelector<BillState>('bill');

export const selectAllPartners = createSelector(
    selectBillState,
    (state: BillState) => state.partners
);

export const selectAllPartnerServices = createSelector(
    selectBillState,
    (state: BillState) => state.partnerServices
);

export const selectAllPartnerServiceFields = createSelector(
    selectBillState,
    (state: BillState) => state.partnerServiceFields
)