import { DomainError } from '../../../../shared/errors/DomainError';

export class AuthError extends DomainError {
	static readonly USER_NOT_EXIST = new AuthError(
		'AUTHENTICATION.ERRORS.USER_NOT_EXIST',
		"L'utilisateur n'existe pas",
		404
	);
	static readonly INVALID_CREDENTIALS = new AuthError(
		'AUTHENTICATION.ERRORS.INVALID_CREDENTIALS',
		'Identifiants invalides',
		401
	);
	static readonly ENROLLMENT_ERROR = new AuthError(
		'AUTHENTICATION.ERRORS.ENROLLMENT_ERROR',
		"Erreur lors de l'inscription",
		400
	);
	static readonly USER_ALREADY_ENROLLED = new AuthError(
		'AUTHENTICATION.ERRORS.USER_ALREADY_ENROLLED',
		"L'utilisateur est déjà inscrit",
		409
	);
	static readonly REGISTRATION_INVALID_RESPONSE = new AuthError(
		'AUTHENTICATION.ERRORS.REGISTRATION_INVALID_RESPONSE',
		"La réponse d'enregistrement est invalide",
		400
	);
	static readonly UNKNOWN_ERROR = new AuthError(
		'AUTHENTICATION.ERRORS.UNKNOWN_ERROR',
		'Une erreur inconnue est survenue',
		500
	);

	static readonly USER_NOT_FOUND = new AuthError(
		'AUTHENTICATION.ERRORS.USER_NOT_FOUND',
		"Échec de l'authentification, veuillez vérifier vos accès et réessayer.",
		404
	);
	static readonly REGISTRATION_FAILED = new AuthError(
		'AUTHENTICATION.ERRORS.REGISTRATION_FAILED',
		"L'utilisateur n'existe pas. Veuillez vérifier vos informations.",
		404
	);

	static readonly RESET_PASSWORD_USER_NOT_FOUND = new AuthError(
		'AUTH.ERRORS.REGISTRATION_FAILED',
		'Information invalide',
		404
	);
	static readonly MAX_RESEND_OTP_ATTEMPTS_REACHED = new AuthError(
		'AUTHENTICATION.ERRORS.MAX_RESEND_OTP_ATTEMPTS_REACHED',
		'Vous avez atteint le nombre maximal de tentatives de renvoi du code OTP.',
		404
	);
	static readonly INVALID_OTP_INFORMATION = new AuthError(
		'AUTHENTICATION.ERRORS.INVALID_OTP_INFORMATION',
		'Le code OTP que vous avez entré est invalide.',
		404
	);
	static readonly RESET_PASSWORD_ERROR = new AuthError(
		'AUTHENTICATION.ERRORS.RESET_PASSWORD_ERROR',
		'Une erreur est survenue',
		404
	);
	static readonly RESEND_ACTIVATE_CODE_ERROR = new AuthError(
		'AUTHENTICATION.ERRORS.RESEND_ACTIVATE_CODE_ERROR',
		'Une erreur est survenue',
		404
	);
	static readonly PASSWORD_ALREADY_USED = new AuthError(
		'AUTHENTICATION.ERRORS.PASSWORD_ALREADY_USED',
		'Une erreur est survenue',
		400
	);
	static readonly INVALID_ACTIVATION_CODE_VALIDATION = new AuthError(
		'AUTHENTICATION.ERRORS.INVALID_ACTIVATION_CODE_VALIDATION',
		'Une erreur est survenue',
		400
	);

	constructor(
		code: string,
		message: string,
		status?: number,
		originalError?: any
	) {
		super(code, message, status, originalError);
	}

	static fromError(error: {
		code: string;
		message: string;
		httpStatusCode?: number;
	}): AuthError {
		console.log('error: ', error);
		switch (error.code) {
			case 'USER_NOT_EXIST':
				return AuthError.USER_NOT_EXIST;
			case 'INVALID_REGISTRATION_RESPONSE':
				return AuthError.REGISTRATION_INVALID_RESPONSE;
			case 'USER_ALREADY_ENROLLED':
				return AuthError.USER_ALREADY_ENROLLED;
			case 'USER_NOT_FOUND':
				return AuthError.USER_NOT_FOUND;
			case 'REGISTRATION_FAILED':
				return AuthError.REGISTRATION_FAILED;
			case 'RESET_PASSWORD_USER_NOT_FOUND':
				return AuthError.RESET_PASSWORD_USER_NOT_FOUND;
			case 'MAX_RESEND_OTP_ATTEMPTS_REACHED':
				return AuthError.MAX_RESEND_OTP_ATTEMPTS_REACHED;
			case 'INVALID_OTP_INFORMATION':
				return AuthError.INVALID_OTP_INFORMATION;
			case 'RESET_PASSWORD_ERROR':
				return AuthError.RESET_PASSWORD_ERROR;
			case 'RESEND_ACTIVATE_CODE_ERROR':
				return AuthError.RESEND_ACTIVATE_CODE_ERROR;
			case 'PASSWORD_ALREADY_USED':
				return AuthError.PASSWORD_ALREADY_USED;
			default:
				return new AuthError(
					'AUTHENTICATION.ERRORS.EXTERNAL_ERROR',
					error.message,
					error.httpStatusCode || 500,
					error
				);
		}
	}
}
